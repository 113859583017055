import { Injectable } from '@angular/core';
import { AppConfig } from '../Shared/AppConfig';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponse } from '../Models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AgentprofileService {
    protected _appset = AppConfig.settings;
    constructor(private http: HttpClient) {
  
    }
  
    async GetAgentKYCDetails(data: any) {
      return this.http.post<ApiResponse>(`${environment.APIUrl}agent/GetAgentKYCDetails`, data,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }
    
    async SaveAadhaar(data: any) {
      return this.http.post<ApiResponse>(`${environment.APIUrl}agent/SaveAadhaar`, data, {
       headers: new HttpHeaders() 
         .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async SavePan(data: any) {
      return this.http.post<ApiResponse>(`${environment.APIUrl}agent/SavePan`, data, {
       headers: new HttpHeaders() 
         .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async GetIFSCInfo(ifscCode?:string) {
      return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetIFSCInfo?iFSCCode=${ifscCode}`,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async GetQuestionInfo(pageNumber:number) {
      return this.http.get<ApiResponse>(`${environment.APIUrl}agent/AcquireQuestions?pageNumber=${pageNumber}`,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async SaveAgentQuestions(data: any) {
      return this.http.post<ApiResponse>(`${environment.APIUrl}agent/SaveExamResponse`, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }
  
    async SaveBankDetails(data: any) {
      return this.http.post<ApiResponse>(`${environment.APIUrl}agent/SaveBankDetails`, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async SaveAgentPicture(data: any) {
      return this.http.post<ApiResponse>(`${environment.APIUrl}agent/SaveAgentImage`, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async SaveAgentQualification(data: any) {
      return this.http.post<ApiResponse>(`${environment.APIUrl}agent/AgentQualification`, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async GetAgentStatus(userId:any) {
      return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetAgentStatus?userId=`+userId,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async AgentDropDown() {
      return this.http.get<ApiResponse>(`${environment.APIUrl}agent/AgentDropDown`,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async GetCustomerByAgentIdMonthYear(data:any) {
      return this.http.post<ApiResponse>(`${environment.APIUrl}agent/GetCustomerByAgentIdMonthYear`,data,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async GetAadharDetailsByUserId(userId:string) {
      return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetAadharDetailsByUserId?userId=${userId}`,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async GetPanDetailsByUserId(userId:string) {
      return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetPanDetailsByUserId?userId=${userId}`,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async GetProfileImgDetailsByUserId(userId:string) {
      return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetProfileImgDetailsByUserId?userId=${userId}`,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async GetQualficationDetailsByUserId(userId:string) {
      return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetQualficationDetailsByUserId?userId=${userId}`,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async GetBankDetailsByUserId(userId:string) {
      return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetBankDetailsByUserId?userId=${userId}`,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async AgentTrainingSessionInfo(data: any) {
      return this.http.post<ApiResponse>(`${environment.APIUrl}agent/AgentTrainingSessionInfo`, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async GetTrainingSessionByUserId(userId:string) {
      return this.http.get<ApiResponse>(`${environment.APIUrl}agent/GetTrainingSessionByUserId?userId=${userId}`,  {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async TotalAgentCommission(data: any) {
      return this.http.post<ApiResponse>(`${environment.APIUrl}agent/TotalAgentCommission`, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }

    async AgentCommissionLedgers(data: any) {
      return this.http.post<ApiResponse>(`${environment.APIUrl}agent/AgentCommissionLedgers`, data, {
        headers: new HttpHeaders()
          .set('Content-Type', 'application/json')
      }).toPromise().catch(async (errorRes: ApiResponse) => {
        let res = new ApiResponse();
        res.isSuccess = false;
        res.message = errorRes.message;
        return res;
      });
    }
  }
