<div class="container">
    <div class="row">
        <div class="col-md-4 mt-5">
            <div class="card p-3">
                <div class="row">
                    <div class="col-md-6">
                        <img src="https://api.bimag.in/portalapi/api/lookUpInfo/GetInsuranceLogoById?InsuranceId=2" width="80%" alt="">
                    </div>
                    <div class="col-md-6">
                        <div class="row">
                            <div class="col-md-4"><span>
                                    Plan:
                                </span></div>
                            <div class="col-md-8">
                                <label for="">Supreme</label>
                            </div>
                            <div class="col-md-4"><span>
                                    Sum Insured:
                                </span></div>
                            <div class="col-md-8">
                                <label for="">₹5 lacs</label>
                            </div>
                            <div class="col-md-5"><span>
                                Tenure:
                            </span></div>
                        <div class="col-md-7">
                            <label for="">1 Year</label>
                        </div>

                        </div>

                    </div>
                  
                    <div class="col-md-12">
                        <hr>
                        <h5 class="h">Premium Breakup</h5>
                        <hr>
                    </div>
                    
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-9">
                                <span style="color:black"><b>Net Premium</b></span>
                            </div>
                            <div class="col-md-3">
                                <span style="color:black"><b>₹{{healthObj.premiumObject.netPremium}}</b></span>
                            </div>
                            <div class="col-md-9 mt-2 hf-cl">
                                <span>GST (18%)</span>
                            </div>
                            <div class="col-md-3 mt-2 hf-cl">
                                <span>+ ₹{{healthObj.premiumObject.igst}}</span>
                            </div>
                        </div>
                        <hr>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="row">
                            <div class="col-md-9 hf-cl">
                                <h5 class="h" style="color:black ">Total Premium</h5>
                            </div>
                            <div class="col-md-3 hf-cl">
                                <h5 class="h" style="color:black">₹{{healthObj.premiumObject.finalPremium}}</h5>

                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2 p-1">
                        <button class="btn btn-primary" id="btn" (click)="paySecurly()">
                            Pay Securely
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8 mt-5">
            <div class="card p-3">
                <div class="row">
                    <div class="col-md-12 p-2    back">
                        <div class="row">
                            <div class="col-md-11 col11">
                                <h5 class="h" style="color:black">Proposer Details &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                        bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                        Edit</span>
                                </h5>
                            </div>

                            <div class="col-md-1  col1" *ngIf="down" (click)="dropDown('down')">
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1  col1" *ngIf="Up" (click)="dropDown('up')">

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="Up">
                        <div class="row">
                            <div class="col-md-6 mt-1">
                                <span>Name</span>
                                <p>{{healthObj.proposerFirstName+' '+healthObj.proposerLastName}} </p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>PAN Card Number</span>
                                <p>{{healthObj.proposerPancard}}</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>Mobile Number</span>
                                <p>{{healthObj.proposerMobile}}</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>Email ID</span>
                                <p>{{healthObj.proposerEmail}}</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>Gender</span>
                                <p>{{healthObj.proposerGender}}</p>
                            </div>
                            <!-- <div class="col-md-6 mt-1">
                                <span>Date of Birth</span>
                                <p></p>
                            </div> -->
                        </div>
                    </div>
                    <div class="col-md-12 mt-2 p-2    back">
                        <div class="row">
                            <div class="col-md-11 col11">
                                <h5 class="h" style="color:black">Address Details &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                        bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                        Edit</span>
                                </h5>
                            </div>

                            <div class="col-md-1  col1" *ngIf="down1" (click)="dropDown('down1')">
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1  col1" *ngIf="Up1" (click)="dropDown('up1')">

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" *ngIf="Up1">
                        <div class="row">
                            <div class="col-md-6 mt-1">
                                <span>Flat Number</span>
                                <p>{{healthObj.FlatNo}}</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>Street Address</span>
                                <p>{{healthObj.StreetAddress}}</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>State </span>
                                <p>{{healthObj.State}}</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>District</span>
                                <p>{{healthObj.District}}</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>Village</span>
                                <p>{{healthObj.City}}</p>
                            </div>
                            <div class="col-md-6 mt-1">
                                <span>PIN Code</span>
                                <p>{{healthObj.Pincode}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2 p-2    back">
                        <div class="row">
                            <div class="col-md-11 col11">
                                <h5 class="h" style="color:black">Members Details &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                        bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                        Edit</span>
                                </h5>
                            </div>

                            <div class="col-md-1  col1" *ngIf="down2" (click)="ChildDown()">
                                <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                            </div>
                            <div class="col-md-1  col1" *ngIf="Up2" (click)="ChildUp()">

                                <img src="../../../assets/svgIcon/Vector.svg" alt="">
                            </div>


                        </div>
                    </div>



                
                <div class="row" *ngIf="child">

                    <ng-container *ngFor="let item of healthObj.InsurerDetails">

                        <div class="col-md-12 mt-2 p-2    back1">
                            <div class="row">
                                <div class="col-md-11 col11">
                                    <h5 class="h" style="color:black">{{item.firstName+' '+item.lastName}}  &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                            bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                            Edit</span>
                                    </h5>
                                </div>
    
                                <div class="col-md-1  col1" *ngIf="chDw" (click)="next('down')" >
                                    <img src="../../../assets/svgIcon/CaretDown.svg" alt="">
    
                                </div>
                                <div class="col-md-1  col1" *ngIf="chUp" (click)="next('up')" >
    
                                    <img src="../../../assets/svgIcon/Vector.svg" alt="">
                                </div>
    
    
                            </div>
                        </div>
                        <div class="col-md-12 mt-1" *ngIf="chUp">   
                            <div class="row">
                                <div class="col-md-6 mt-2">
                                    <span>Name</span>
                                    <p>{{item.firstName+' '+item.lastName}}</p>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <span>Date of Birth</span>
                                    <p>{{item.dateOfBirth}}</p>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <span>Height</span>
                                    <p>{{item.height}}</p>
                                </div>
                                <div class="col-md-6 mt-2">
                                    <span>Weight</span>
                                    <p>{{item.weight}}</p>
                                </div>  
                            </div>
                        </div>

                    </ng-container>     


                </div>
                <div class="col-md-12 mt-2 p-2    back">
                    <div class="row">
                        <div class="col-md-11 col11">
                            <h5 class="h" style="color:black">Nominees Details   &nbsp;&nbsp;<span style="color:var(--main);   position: relative;
                                    bottom: 4px;"><img src="../../../assets/svgIcon/Vector (3).svg" alt="">
                                    Edit</span>
                            </h5>
                        </div>

                        <div class="col-md-1  col1" *ngIf="down3" (click)="dropDown('down3')">
                            <img src="../../../assets/svgIcon/CaretDown.svg" alt="">

                        </div>
                        <div class="col-md-1  col1" *ngIf="Up3" (click)="dropDown('up3')">

                            <img src="../../../assets/svgIcon/Vector.svg" alt="">
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-2" *ngIf="Up3">
                    <div class="row">
                        <div class="col-md-6 mt-1">
                            <span>Nominees Name</span>
                            <p>{{healthObj.nomineeFirstName+' '+healthObj.nomineeLastName}}</p>
                        </div>
                        <div class="col-md-6 mt-1">
                            <span>Relation</span>
                            <p>{{healthObj.nomineeRelationWithProposer}}</p>
                        </div>
                        <div class="col-md-6 mt-1">
                            <span>DOB </span>
                            <p>{{healthObj.nomineeDOB}}</p>
                        </div>
                        <div class="col-md-6 mt-1">
                            <span>Gender</span>
                            <p>{{healthObj.nomineeGender}}</p>
                        </div>
                       
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>  