<div class="container">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#009ef7" type="ball-spin-clockwise-fade-rotating"
    name="CH"></ngx-spinner>
  <div class="bg-grey9" *ngIf="isVerifyVehicleDetails">
    <div class="row content d-flex justify-content-between ">
      <div class="col-12 col-md-6 col-lg col-xl">
        <br>
        <img src="../../assets/images/Commercail.png" alt="" style="width: 100%; max-width: 100%;">
      </div>
      <div class="col-12 col-md-6 col-lg col-xl  d-flex flex-column justify-content-center">
        <div *ngIf="!homeForm1">
          <form [formGroup]="insuranceForm" class="needs-validation" novalidate="" autocomplete="off">
          <div>
            <h2 class="heading text-left">
              {{langList.comm_ins_title}}
            </h2>
            <h5 class="text-left text-grey4 fw-medium mt-3">{{langList.comm_ins_start}}
            </h5>
          </div>
          <br>
            <div class="d-flex">
              <input *ngIf="isMobile" type="text" placeholder="{{langList.comm_vcl_number}}"
                class="form-control input-control mobileIcon" [(ngModel)]="vehicleNumber"
                [minlength]="10" formControlName="registrationNumber"
                (keyup)="formatRegNo($event)" 
                (input)="checkRegistrationNumber($event)"
                (paste)="checkRegistrationNumber($event)"
                oninput="this.value = this.value.toUpperCase()">
              <input *ngIf="isOtp" type="text" placeholder="Enter OTP" class="form-control input-control starIcon">
              <button class="btn btn-primary inputButton" (click)="PopupOpen(1)"
                [disabled]="onVehicleRegNumberInput() || !vehicleNumber">
                {{langList.verify}}
              </button>
            </div>
          <div class="text-danger" *ngIf="this.insuranceForm.get('registrationNumber')?.dirty && onVehicleRegNumberInput()">Please enter Valid Vehicle Number.</div>
          <div class="d-flex justify-content-between align-items-center mt-2"> <a
              class="font-body float-start text-main font-15-12" (click)="PopupOpen(2)">
              <!-- Port my existing policy -->
              {{langList.dont_have_policy}}
            </a>
            <a class="font-body float-end text-main font-15-12" (click)="PopupOpen(3)">
              <!-- Renew your policy -->
              {{langList.bought_new_vcl}}
            </a>
          </div>
        </form>
          <!-- <div>
        <h2 class="heading text-left">Get best health insurance for you
          and your family.</h2>
        <h5 class="text-left text-grey4 fw-medium mt-3">Select for whom you want to buy Insurance</h5>
      </div>
      <br> -->
          <!-- <div class="d-flex">
        <div class="insurance_tabs">
          <ul class="justify-content-between align-items-center mb-0 flex-row">
            <li class="list-item" [ngClass]="{'active_tab': activeTabs == 1}" (click)="insuranceTab('individual')">
              Individual
            </li> 
            <li class="list-item" [ngClass]="{'active_tab': activeTabs == 2}" (click)="insuranceTab('family')">
              Family
            </li>    
          </ul>
        </div>
      </div> -->
          <!-- <div class="d-flex justify-content-between align-items-center">
        <a class="font-body float-start text-main font-15-12" href="#">
          Port my existing policy
        </a>
        <a class="font-body float-end text-main font-15-12" href="#">Renew your policy</a>
      </div> -->
          <!-- <h4 class="mt-10" style="color: var(--main2);">"Exciting News! We're gearing up to launch Commercial
            Vehicle Insurance soon!!"</h4> -->
        </div>
      </div>

      <div class="card shadow my-5" *ngIf="homeForm1" style="width: 550px;">
        <ul class="row p-3" style="margin: 0px 5px;">
          <li class="col selected-menu active" onclick="changeMenu(event, 1)">
            <a href="#1a" data-toggle="tab">
              <h3>Details</h3>
            </a>
          </li>&nbsp;
          <li class="col selected-menu" onclick="changeMenu(event, 2)">
            <a href="#2a" data-toggle="tab">
              <h3>Property Type</h3>
            </a>
          </li>&nbsp;
          <li class="col selected-menu" onclick="changeMenu(event, 3)">
            <a href="#3a" data-toggle="tab">
              <h3>Coverage</h3>
            </a>
          </li>&nbsp;
        </ul>
        <div class="tab-content clearfix">
          <div id="1a" class="tab-pane active">
            <h3 style="padding: 0px 40px;">Where your property?</h3>
            <div class="row p-3 justify-content-between">
              <div class="col-5">
                <label for="address" class="text-left d-flex">Street Address</label>
                <input type="text" class="form-control">
              </div>
              <div class="col-5">
                <label for="pincode" class="text-left d-flex">Your Pincode</label>
                <input type="text" class="form-control">
              </div>
            </div>
            <div class="row p-3 justify-content-between">
              <div class="col-5">
                <label for="vilage" class="text-left d-flex">Select Village</label>
                <select class="form-control" name="vilage" id="vilage">
                  <option value="select">Select Village</option>
                  <option value="07">Jaipur</option>
                  <option value="08">Jodhpur</option>
                  <option value="09">Udaipur</option>
                </select>
              </div>
              <div class="col-5"></div>
            </div>
          </div>
          <div id="2a" class="tab-pane">
            <h4 style="padding: 0px 40px; text-align: left;">Are you the owner or tenant?</h4>
            <div class="row p-3 justify-content-between">
              <div class="col-5 property-type">
                <div class="d-flex justify-content-between">
                  <div>
                    <img src="../../assets/svgIcon/houseOwner.svg" alt="">
                  </div>
                  <div>
                    <h5>Owner</h5>
                  </div>
                  <div>
                    <input type="checkbox">
                  </div>
                </div>
                <div class="mt-2">
                  <span class="font-body d-flex" style="text-align: left;">The Person who owns the property
                  </span>
                </div>
              </div>
              <div class="col-5 property-type">
                <div class="d-flex justify-content-between">
                  <div>
                    <img src="../../assets/svgIcon/houseRent.svg" alt="">
                  </div>
                  <div>
                    <h5>Tenant</h5>
                  </div>
                  <div>
                    <input type="checkbox">
                  </div>
                </div>
                <div class="mt-2">
                  <span class="font-body d-flex" style="text-align: left;">The Person who rents the property
                  </span>
                </div>
              </div>
            </div>
            <h4 class="mt-3" style="padding: 0px 40px; text-align: left;">
              Is your home a flat or an independent
              house?
            </h4>
            <div class="row p-3 justify-content-between">
              <div class="col-5 property-type" style="height: 104px;">
                <div class="d-flex justify-content-between">
                  <div>
                    <img src="../../assets/svgIcon/flat.svg" alt="">
                  </div>
                  <div>
                    <h5>Flat</h5>
                  </div>
                  <div>
                    <input type="checkbox">
                  </div>
                </div>
              </div>
              <div class="col-5 property-type" style="height: 104px;">
                <div class="d-flex justify-content-between">
                  <div>
                    <img src="../../assets/svgIcon/villa.svg" alt="">
                  </div>
                  <div>
                    <h5>Independent<br> House</h5>
                  </div>
                  <div>
                    <input type="checkbox">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="3a" class="tab-pane" style="padding: 0px 10px;">
            <h4 style="padding: 10px; text-align: left;">What do you want to insure?</h4>
            <div class="row p-3 justify-content-between">
              <div class="col-3 insureType">
                <div class="d-flex justify-content-between">
                  <div>
                    <img class="imgHeight" src="../../assets/svgIcon/property.svg" alt="">
                  </div>
                  <div>
                    <input type="checkbox">
                  </div>
                </div>
                <div class="mt-2">
                  <h5 class="headerTxt">Only Building</h5>
                  <span class="font-body d-flex" style="text-align: left;">The Person who owns the property
                  </span>
                </div>
              </div>
              <div class="col-3 insureType">
                <div class="d-flex justify-content-between">
                  <div>
                    <img class="imgHeight" src="../../assets/svgIcon/household.svg" alt="">
                  </div>
                  <div>
                    <input type="checkbox">
                  </div>
                </div>
                <div class="mt-2">
                  <h5 class="headerTxt">Household Items</h5>
                  <span class="font-body d-flex" style="text-align: left;">The Person who rents the property
                  </span>
                </div>
              </div>
              <div class="col-3 insureType">
                <div class="d-flex justify-content-between">
                  <div>
                    <img class="imgHeight" src="../../assets/svgIcon/both.svg" alt="">
                  </div>
                  <div>
                    <input type="checkbox">
                  </div>
                </div>
                <div class="mt-2">
                  <h5 class="headerTxt">Both</h5>
                  <span class="font-body d-flex" style="text-align: left;">The Person who rents the property
                  </span>
                </div>
              </div>
            </div>
            <h3 style="padding: 10px; text-align: left;">Enter value of Building & Carpet area</h3>
            <div class="row p-3">
              <div class="col-5">
                <label for="address" class="text-left d-flex">Value of Building (Rs.)</label>
                <input type="text" class="form-control" placeholder="Enter Value">
              </div>
              <div class="col-5">
                <label for="pincode" class="text-left d-flex">Value of Household Items (Rs.)</label>
                <input type="text" class="form-control" placeholder="Enter Value">
              </div>
            </div>
            <div class="row p-3">
              <div class="col-5">
                <label for="address" class="text-left d-flex">Carpet Area (sq. ft.)</label>
                <input type="text" class="form-control" placeholder="Enter Value">
              </div>
              <div class="col-5">
                <label for="pincode" class="text-left d-flex">Cost of Construction (sq. ft.)</label>
                <input type="text" class="form-control" placeholder="Enter Value">
              </div>
            </div>
          </div>
        </div>
        <div class="p-5">
          <a href="#{{selectedTab}}a" data-toggle="tab">
            <button class="btn btn-light float-start" (click)="back()">Back</button>
          </a>
          <a href="#{{selectedTab}}a" data-toggle="tab">
            <button class="btn btn-primary float-end" *ngIf="this.selectedTab < 3" (click)="continue()">
              Continue
            </button>
            <button class="btn btn-primary float-end" *ngIf="this.selectedTab >= 3" routerLink="/property">View
              plans</button>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="isPopup">
    <div class="popup2 col-md-5 col-xs-12 col-sm-8 col-11">
      <div class="modal-header tooltip_">
        <h5 _ngcontent-nnb-c116="" class="modal-title">
          Commercial Vehicle &nbsp;&nbsp;&nbsp;<span><img src="../../assets/svgIcon/1-2.svg" alt=""
              *ngIf="firstPage"><img src="../../assets/svgIcon/2-2.svg" alt="" *ngIf="secondPage"></span>
        </h5>
        <button type="button" aria-label="Close" class="close btn pull-right" (click)="close()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="vehicleForm" class="needs-validation policy_form" novalidate="" autocomplete="off">
          <div class="row " *ngIf="firstPage">
            <!-- <form [formGroup]="FirstPolices"> -->
            <div class="col-md-12">
              <p class="p-tag mb-1 fw-bolder">
                Select the category of your vehicle
              </p>
            </div>
            <ng-container *ngFor="let cat of CommVehicleCategoryList">
              <div class="col-md-12" (click)="Types(cat.value)">
                <div class="card crd mx-0 py-1" [ngClass]="{'selected':vehicleFormControl['category'].value == cat.value}">
                  <div class="row align-items-center">
                    <div class="col-md-3 col-3 ">
                      <img src="../../assets/svgIcon/{{cat.text1}}" width="50%" height="100%" alt="">
                    </div>
                    <div class="col-md-6 col-6">
                      <h6 class="vehicle mb-0">{{cat.text}}</h6>
                    </div>
                    <div class="col-md-3 col-3 text-end ">
                      <img src="../../assets/svgIcon/qusmark.svg" width="40%" height="100%" alt="" [placement]="'top'"
                        [tooltip]="cat.tooltip">
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- <div class="col-md-12" (click)="Types('PCV')">
            <div class="card crd"  [ngClass]="{'selected':vehicleFormControl['category'].value == 'PCV'}">
              <div class="row">
                <div class="col-md-3 col3 ">
                  <img src="../../assets/svgIcon/riksha.svg" width="50%" height="100%" alt="">
                </div>
                <div class="col-md-6 col6  mt-2">
                  <h6 class="vehicle">PCV</h6>
                </div>
                <div class="col-md-3 col3 text-end ">
                  <img src="../../assets/svgIcon/qusmark.svg" width="40%" height="100%" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-1" (click)="Types('GCV')">
            <div class="card crd  " [ngClass]="{'selected':vehicleFormControl['category'].value == 'GCV'}">
              <div class="row rw-mg">
                <div class="col-md-3 col3 ">
                  <img src="../../assets/svgIcon/truck1.svg" width="50%" height="100%" alt="">
                </div>
                <div class="col-md-6 col6  mt-2">
                  <h6 class="vehicle">GCV</h6>
                </div>
                <div class="col-md-3 col3 text-end ">
                  <img src="../../assets/svgIcon/qusmark.svg" width="40%" height="100%" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12 mt-1" (click)="Types('MISC')">
            <div class="card crd  " [ngClass]="{'selected':vehicleFormControl['category'].value == 'MISC'}">
              <div class="row rw-mg">
                <div class="col-md-3 col3 ">
                  <img src="../../assets/svgIcon/jcb.svg" width="50%" height="100%" alt="">
                </div>
                <div class="col-md-6 col6  mt-2">
                  <h6 class="vehicle">Misc...</h6>
                </div>
                <div class="col-md-3 col3 text-end ">
                  <img src="../../assets/svgIcon/qusmark.svg" width="40%" height="100%" alt="">
                </div>
              </div>
            </div>
          </div> -->

            <!-- <div class="col-md-12" *ngIf="vehicleFormControl['category'].value == 'PCV'">
            <div class="">
              <div class="col-12 tool">
                <div class=" sp-font"><span>PCV: Passenger Carrying Vehicles refer to vehicles that are designed and used
                  primarily for the transportation of passengers. </span></div>
              </div>
            </div>            
          </div>
  
          <div class="col-md-12" *ngIf="vehicleFormControl['category'].value == 'GCV'">
            <div class="">
              <div class="col-12 tool">
                <div class=" sp-font"><span>GCV: Goods Carrying Vehicles refer to vehicles that are designed and used
                primarily for transporting goods or cargo. </span></div>
              </div>
            </div>
          </div>
          <div class="col-md-12" *ngIf="vehicleFormControl['category'].value == 'MISC'">
            <div class="">
              <div class="col-12 tool">
                <div class=" sp-font"><span>Misc:Miscellaneous vehicle are used in works such as construction or in emergency
                  services such as ambulance or fire suppressing vehicles. </span></div>
              </div>
            </div>
          </div> -->
            <div class="col-md-12 mt-3">
              <p class="p-tag mb-0 fw-bolder"
                *ngIf="vehicleFormControl['category'].value == 'PCV' || vehicleFormControl['category'].value == 'GCV' || vehicleFormControl['category'].value == 'MISC'">
                Vehicle Type</p>
            </div>
            <div class="col-md-12 mt-2">
              <div class="">
                <div class="row">
                  <div class="col-md-12" *ngIf="vehicleFormControl['category'].value == 'PCV'">
                    <select class="form-select " #Pcv aria-label="Default select example" (change)="PVCType($event)"
                      formControlName="pcVehicle">
                      <option selected value="">PCV Vehicle Type</option>
                      <option *ngFor="let PCV of CommVehiclePCVehicleList" value="{{PCV.value}}">{{PCV.text}}</option>
                    </select>
                    <ng-container
                      *ngIf="vehicleFormControl['pcVehicle'].value == 'Bus' && vehicleFormControl['category'].value == 'PCV'">
                      <label for="" class="mt-3 fw-bolder"> Seaters</label>
                      <select class="form-select stl" aria-label="Default select example" (change)="SeaterType($event)"
                        formControlName="seatingCap">
                        <option selected value="">Select Seaters Type</option>
                        <option *ngFor="let seat of CommVehiclePassCapList" value="{{seat.value}}">{{seat.text}}
                        </option>
                      </select>
                    </ng-container>
                  </div>
                  <!-- <div class="col-md-12 mt-3" *ngIf="vehicleFormControl['pcVehicle'].value == 'Bus' && vehicleFormControl['category'].value == 'PCV'">
                  <label for="" class="fw-bolder"> Purpose</label>
                  <select class="form-select stl" aria-label="Default select example" formControlName="purpose">
                    <option selected value="">Select Your Purpose</option>
                    <option *ngFor="let purpose of CommVehiclePurposeList" value="{{purpose.value}}">{{purpose.text}}</option>
                  </select>
                </div> -->
                  <div class="col-md-12" *ngIf="vehicleFormControl['category'].value == 'GCV'">
                    <select class="form-select" aria-label="Default select example" formControlName="gcVehicle">
                      <option selected value="">GCV Vehicle Type</option>
                      <option *ngFor="let GCV of CommVehicleGCVehicleList" value="{{GCV.value}}">{{GCV.text}}</option>
                    </select>
                  </div>
                  <div class="col-md-12" *ngIf="vehicleFormControl['category'].value == 'MISC'">
                    <select class="form-select" aria-label="Default select example" formControlName="miscVehicle">
                      <option selected value="">MISC Vehicle Type</option>
                      <option *ngFor="let MISC of CommVehicleMISCVehicleList" value="{{MISC.value}}">{{MISC.text}}
                      </option>
                    </select>
                  </div>
                  <!-- <div class="col-md-12" *ngIf="vehicleFormControl['category'].value == 'MISC' || vehicleFormControl['category'].value == 'GCV'">
                  <p class="p-tag mb-1 mt-3 fw-bolder">
                    GVW
                  </p>
                  <select class="form-select" aria-label="Default select example" formControlName="gVW">
                    <option selected value="">GVW</option>
                    <option *ngFor="let gvw of CommVehicleWeightList" value="{{gvw.value}}">{{gvw.text}}</option>    
                  </select>
                </div>     -->
                </div>
              </div>
            </div>
            <div class="col-md-12 ">
              <p class="p-tag mb-0 mt-3 fw-bolder ">
                Select the Permit type of your vehicle
              </p>
            </div>
            <div class="col-md-6  cur" (click)="permit('public')">
              <div class="card shad pl-2" [ngClass]="{'imgSelect':vehicleFormControl['permit'].value == 'public'}">
                <div class="row align-items-center height-42">
                  <div class="col-auto">
                    <img src="../../assets/svgIcon/IdentificationCard.svg" width="32px" alt="">
                  </div>
                  <div class="col-8">
                    <p class="font-body mb-0">Public</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 cur" (click)="permit('private')">
              <div class="card shad pl-2" [ngClass]="{'imgSelect':vehicleFormControl['permit'].value == 'private'}">
                <div class="row  align-items-center height-42">
                  <div class="col-auto">
                    <img src="../../assets/svgIcon/IdentificationCard.svg" width="32px" alt="">
                  </div>
                  <div class="col-8">
                    <p class="font-body mb-0">Private</p>
                  </div>
                </div>
              </div>
            </div>
            <!-- </form> -->
          </div>
          <div class="row " *ngIf="secondPage">
              <div class="col-md-12 d-flex align-items-center gap-8 mb-5">
                <button type="button" class="btn btn-primary px-1 py-1">
                  <img src="../../assets/svgIcon/ArrowLeft.svg" alt="ArrowLeft"
                    class="cursor-pointer svg_white edit-icon" (click)="secondPage = false;firstPage=true;">
                </button>
                <p class="heading_">Existing Policy Details</p>
              </div>
              <div class="col-md-6 form-group text-left">
                <label class="required">Policy's Expiry Status</label><img src="/assets/images/Vector4.png" alt=""
                  (click)="openPolicyStatusModal()" class="cursor-pointer ml-2">
                <select class="form-select" formControlName="policyExpiryStatus" [(ngModel)]="policyExpiryStatus"
                  (change)="policyDetail($event)">
                  <option *ngFor="let item of policyExpiryStatusList; let i = index;" value="{{item.value}}">
                    {{item.text}}</option>
                </select>
              </div>
            <ng-container *ngIf="this.vehicleForm.get('policyExpiryStatus')?.value=='1' || this.vehicleForm.get('policyExpiryStatus')?.value=='2'">
              <div class="col-md-6 form-group text-left">
                <label class="required">Existing Policy Company</label>
                <app-autocomplete class="w-100" [items]="insurancePartnersList"
                  placeholder="Type and search Current policy company" (itemSelected)="onPrevCompSelection($event)"
                  (onBlurEvent)="onPrevCompBlur($event)" [userInput]="previousPolicyCompany"
                  [className]="'py-6_5 shadow_'" [showError]="showPreviousPolicyCompanyError"></app-autocomplete>
                <span class="text-danger" *ngIf="showPreviousPolicyCompanyError">Current policy company is
                  required.</span>
              </div>
              <div class="col-md-6 form-group text-left">
                <label class="required">Existing Policy Expiry Date</label>
                <input type="date" name="previousPolicyExpiryDate" formControlName="previousPolicyExpiryDate"
                  class="form-control py-6_5 shadow_" [max]="maxPolicyExpiryDate" [min]="minPolicyExpiryDate"
                  onkeydown="return false">
                <!-- <span class="text-danger" *ngIf="showPreviousPolicyCompanyError">Current policy company is required.</span> -->
              </div>
            </ng-container>
            <div class="col-md-6 form-group text-left">
              <label class="required">Policy Type</label>
              <select class="form-select" [(ngModel)]="policyType" formControlName="policyType"
                (change)="coverageDetail($event)">
                <option *ngFor="let item of policyTypeList; let i = index;" [value]="item.value">{{item.text}}
                </option>
              </select>
            </div>
            <ng-container *ngIf="this.vehicleForm.get('previousPolicyType')?.value == 'Comprehensive'">
              <div class="col-md-6 form-group text-left">
                <label>Did you make a claim in your existing policy?</label>
                <select class="form-select" formControlName="policyClaimed" [(ngModel)]="policyClaimed"
                  (change)="preClaimDetail($event)">
                  <option *ngFor="let item of policyClaimedList; let i = index;" value="{{item.value}}">{{item.text}}
                  </option>
                </select>
              </div>
              <ng-container *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'Yes'">
                  <div class="col-md-6 form-group text-left">
                    <label>Number of claim(s) made in existing policy</label>
                    <select class="form-select" formControlName="claimsMadeInPreviousPolicyNo" [(ngModel)]="claimsMadeInPreviousPolicyNo"
                      (change)="claimCountChanged($event)">
                      <option *ngFor="let item of policyNcbClaimMadeList; let i = index;" value="{{item.value}}">
                        {{item.text}}</option>
                    </select>
                  </div>
              </ng-container>
              <ng-container *ngIf="vehicleFormControl['claimsMadeInPreviousPolicy'].value == 'No'">
                  <div class="col-md-6 form-group text-left">
                    <label>Existing NCB (No Claim Bonus) <img src="/assets/images/Vector4.png" alt=""
                        (click)="openNCBModal()" class="cursor-pointer"></label>
                    <select class="form-select" formControlName="policyClaimedNCB" [(ngModel)]="policyClaimedNCB"
                      (change)="nsbValDetail($event)">
                      <option *ngFor="let item of policyNcbClaimedList; let i = index;" value="{{item.value}}">
                        {{item.text}}</option>
                    </select>
                  </div>
              </ng-container>
            </ng-container>
          </div>
        </form>
      </div>
      <div class="row  mt-4">
        <div>
        <button class="con_btn mr-3 mb-3"
          [disabled]="validateVehicleCategory()" *ngIf="firstPage" (click)="changePage()">
          Continue
        </button>
        <button class="con_btn mr-3 mb-3" *ngIf="secondPage"
          (click)="gotoVehicle()" [disabled]="validateExistingPolicyDetails()">
          Continue
        </button>
      </div>
      </div>
    </div>
  </div>

  <div [ngClass]="{'d-none': !isDontKnow}">
    <app-commercial-vechicles (dataEvent)="ShowQuotes($event)" (gotoEvent)="goTo($event)"></app-commercial-vechicles>
  </div>
  <div [ngClass]="{'d-none': !isQuotes}">
    <vehiclequotes (dataEvent)="ShowQuoteDetials($event)" (gotoEvent)="goTo($event)"></vehiclequotes>
  </div>
  <div [ngClass]="{'d-none': !isProposer}">
    <vehicle-owner-details [ownerDetails]="this.vehicleForm.value" (dataEvent)="ShowPolicyDetailsPage($event)"
      (gotoEvent)="goTo($event)"></vehicle-owner-details>
  </div>
  <div *ngIf="isRoyalSundaram">
    <app-royal-sundaram-payment [royalDetails]="royalDetails"></app-royal-sundaram-payment>
  </div>
  <div *ngIf="isFutureGeneral">
    <app-future-general-payment [futureDetails]="futureDetails"></app-future-general-payment>
  </div>
  <div *ngIf="isRelianceGeneral">
    <app-reliance-general-payment [RelianceDetails]="RelianceDetails"></app-reliance-general-payment>
  </div>

  <div *ngIf="isShriramGeneral">
    <app-shriramgeneral-payment [ShriramDetails]="ShriramDetails"></app-shriramgeneral-payment>
  </div>
  <div *ngIf="isPaymentLink">
    <app-payment-progress [paymentObject]="paymentObject"></app-payment-progress>
  </div>
  <div class="overlay" *ngIf="showNCBTooltip">
    <div class="popup2 position-relative col-md-4 col-11">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>No Claim Bonus (NCB) </h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeNCBModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <p class="text-grey4">No Claim Bonus is a discount offered to policyholders on their insurance premiums when
            they haven't made any claims during the policy term. The longer you go without a claim, the higher your NCB,
            saving you money on your insurance costs.</p>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showPolicyStatusTooltip">
    <div class="popup2 position-relative col-md-4 col-11">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>Policy Expiry Status</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closePolicyStatusModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body">
        <div class="">
          <span class="font-17-13 text-grey5 d-block mt-2">Not Expired</span>
          <span class="font-15-12 text-grey4">Hooray! you will get your policy instantly</span>
          <span class="font-17-13 text-grey5 d-block mt-2">Expired Within 90 Days</span>
          <span class="font-15-12 text-grey4">Pre-inspection required</span>
          <span class="font-17-13 text-grey5 d-block mt-2">Expired More than 90 Days</span>
          <span class="font-15-12 text-grey4">Pre-inspection required, NCB discound not applicable</span>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showKYCPopup">
    <div class="popup2 position-relative col-md-11 col-11 height90">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>Complete KYC</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeKYCModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative height90">
        <iframe id="ifr2" [src]="kycUrl" target="_parent" frameborder='0' class="iframeclass"></iframe>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showhttpKYCPopup">
    <div class="popup2 position-relative col-md-11 col-11 height90">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4>Complete KYC</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closehttpKYCModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative height90">

        <span class="font-17-13 text-grey5 d-block mt-2">Please use below link to update your KYC details</span>
        <br />
        <a id="failedKYCLink" target="_blank" (click)="closehttpKYCModal()" [href]="kycUrl">Click here to update your
          KYC details</a>
        <br />
        <span class="font-17-13 text-grey5 d-block mt-2">Once your KYC is done, return to this window and click on the
          pay button</span>

      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showIsBreakIn">
    <div class="popup2 position-relative col-md-4 col-11 ">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4 class="mb-0">Vehicle inspection required</h4>
        </div>
        <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeBreakInModal()">
          <span aria-hidden="true"></span>
        </button>
      </div>
      <div class="modal-body position-relative text-center">
        <span class="font-17-13 text-grey5 d-block mt-2">You will need to upload a short video of your vehicle. It takes only a few minutes. Do you want to Continue?</span>
        <div class="row mt-8 justify-content-around">
            <button type="button" class="btn btn-primary col-auto" aria-label="Close" (click)="CreatePolicy()">
                Yes Continue
              </button>
              <button type="button" class="btn btn-secondary col-auto" aria-label="Close" (click)="closeBreakInModal()">
                No
              </button>
        </div>
      </div>
    </div>
  </div>
  <div class="overlay" *ngIf="showIsBreakInTriggered">
    <div class="popup2 position-relative col-md-8 col-11 ">
      <div class="modal-header tooltip_">
        <div class="modal-title">
          <h4 class="mb-0">Inspection Request Submitted</h4>
        </div>
        <!-- <button type="button" class="close pull-right close1" aria-label="Close" (click)="closeBreakInModal()">
          <span aria-hidden="true"></span>
        </button> -->
      </div>
      <div class="modal-body position-relative">
        <div class="row">
            <div class="col-md-4 d-flex align-item-center">
                <img src="../../../assets/svgIcon/list_clipboard.svg" class="img-fluid">
            </div>
            <div class="col-md-8 d-flex align-item-center">
                <div class="row">
                    <div class="col-md-12 text-main font-15-12">
                        Please complete the inspection process by clicking on the link  you received in your phone.
                        After completing this process, it will take approximate 48 hours to verify your vehicle’s inspection details.
                        Please use {{this.insuranceForm.get('quoteId')?.value}} - your reference ID for the update. However, we will notify you once inspection is verified.
                    </div>
                    <div class="col-md-12 mt-5 text-main font-15-12">
                        <span class="d-block">You can continue your policy journey with the following steps:</span>
                        <span class="d-block"><span class="text-main2">Step 1: </span>Login with your credentials.</span>
                        <span class="d-block"><span class="text-main2">Step 2: </span>Go to Dashboard.</span>
                        <span class="d-block"><span class="text-main2">Step 3: </span>Click on Incomplete tab available in Policies menu option.</span>
                        <span class="d-block"><span class="text-main2">Step 4: </span>Select your policy and click on Continue button.</span>
                        <span class="d-block"><span class="text-main2">Step 5: </span>Go to payment, and complete the transaction.</span>
                        <span class="d-block"><span class="text-main2">Step 6: </span>Congratulations! Policy is issued for your vehicle.</span>
                    </div>
                    <div class="col-12">
                        <button type="button" class="btn btn-primary col-md-6" aria-label="Close" (click)="goToPolicyDetails()">
                            Ok
                          </button>
                    </div>
                </div>    
            </div>
        </div>
      </div>
    </div>
  </div>
</div>




<div class="banner">
  <div class="  mt-5 mb-5 ">
    <div class="bannerDIv">

      <div><img src="./assets/images/newicons/c11.png"></div>

      <div><img src="./assets/images/newicons/c22.png"></div>

      <div><img src="./assets/images/newicons/c33.png"></div>

      <div><img src="./assets/images/newicons/c44.png"></div>

    </div>
  </div>

</div>


<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 class="whatinsh1">What is Commercial Vehicle
          Insurance?
        </h2>
        <span>
          <p style="padding-top: 15px;" class="insText">Commercial Vehicle Insurance is a specialized policy designed to
            protect vehicles used for business purposes. This insurance provides coverage for a wide range of vehicles,
            including trucks, vans, buses, taxis, and other vehicles used to transport goods or passengers for a fee.
            Comprehensive policies combine both third-party and own damage coverage, with optional add-ons like roadside
            assistance and zero depreciation to enhance protection. Keep your business on the move with peace of mind.
            Get a quote today and drive worry-free!</p>

        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;margin-bottom: 20px;">
          <a class="earnButton">Know More About Insurance</a>
        </div> -->
      </div>
      <div class="col-md-5 whatimage">
        <img src="./assets/images/newicons/truck.svg" class="imgresponsive">
      </div>
    </div>
  </div>
</div>

<div class="whyins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5 whyimage">
        <img src="./assets/images/newicons/whytruck.svg" class="imgresponsive">
      </div>
      <div class="col-md-7">
        <h2 class="whyinsh1">
          Who Should Insure a Commercial Vehicle ?</h2>
        <span>
          <p style="padding-top: 20px;" class="insText">
            1. Business owners with a fleet of commercial vehicles (e.g., trucks, vans, delivery vehicles).<br>
            2. Transport and logistics companies.<br>
            3. Taxi and ride-sharing service and Passenger transport service providers.<br>
            4. Construction companies using heavy machinery and vehicles.<br>
            5. Agricultural businesses using tractors, harvesters, and other vehicles.
          </p>
        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;">
          <a class="earnButton">Know more about it</a>
        </div> -->

      </div>

    </div>
  </div>
</div>


<div class="click4bima">
  <div class="  mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12">
        <div class="">
          <h1 class="whyinsh1" style="text-align: center !important; color: #fff !important;">Types of Commercial
            Vehicle Insurance</h1>
        </div>
        <div class="tableClass" [align]="'center'">
          <table>
            <thead>
              <tr>
                <th [width]="'15%'">Key Features</th>
                <th>Comprehensive</th>
                <th>Third-Party(TP)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Description</th>
                <td>Comprehensive insurance plan offers protection against both own damage and third-party liability,
                  covering repair costs for your vehicle and compensating for injuries or property damage caused to
                  others. This all-inclusive coverage ensures financial security and peace of mind for business
                  operations involving commercial vehicles.</td>
                <td>Third-Party insurance plan provides coverage for liabilities arising from damage or injury caused to
                  other people or their property by your commercial vehicle. It ensures financial protection against
                  legal claims and helps meet regulatory requirements.</td>
              </tr>
              <tr>
                <th>Coverage</th>
                <td>
                  <ul>
                    <li>Own Damage</li>
                    <li>Third-Party Liabilities</li>
                    <li>Accident, Theft, Fire, Natural Calamities</li>
                  </ul>
                </td>
                <td>
                  Third-Party Liabilities
                </td>
              </tr>
              <tr>
                <th>Ahd-ons Availability</th>
                <td>
                  <ul>
                    <li>Zero/Nil Dep</li>
                    <li>Consumable Cover</li>
                    <li>RTI</li>
                    <li>RSA</li>
                    <li>Engine & Gear Box</li>
                    <li>IMT23 (GCV only)</li>
                  </ul>
                </td>
                <td>Not Available</td>
              </tr>
              <tr>
                <th>Premium</th>
                <td>High</td>
                <td>Low</td>
              </tr>
              <tr>
                <th>Pros</th>
                <td>
                  <ul>
                    <li>Wide Coverage</li>
                    <li>Additional Benefits with Add-ons</li>
                    <li>Financial Security for Own and Third-Party Damage</li>
                    <li>Legal Compliance</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Financial Security for Third-Party Damage</li>
                    <li>Legal Compliance</li>
                    <li>Lower Premium</li>
                    <li>PA Cover</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <th>Cons</th>
                <td>
                  <ul>
                    <li>Higher Premiums</li>
                    <li>Depreciation Deduction</li>
                  </ul>
                </td>
                <td>
                  <ul>
                    <li>Limited Coverage</li>
                    <li>No Financial Protection for Own Damage</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="Adddonnns">
  <div style="text-align: center;margin-top: 50px;">
    <h2 style="font-size: 40px; ">Add-Ons We Provide for Commercial Vehicles</h2>
  </div>
  <div class="desktop-view">
    <div class="addonDIvflax">
      <div class="addonDIv" [ngClass]="{ 'activeDIv': types === 1 }" (click)="getValue(1)">
        <div>
          <img src="./assets/images/newicons/b1.svg">
        </div>
        <div>
          Zero/Nil<br>
          Depreciation
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': types === 2 }" (click)="getValue(2)">
        <div>
          <img src="./assets/images/newicons/b2.svg">
        </div>
        <div>
          Consumable<br>
          Cover
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': types === 3 }" (click)="getValue(3)">
        <div>
          <img src="./assets/images/newicons/b3.svg">
        </div>
        <div>
          Return to<br>
          Invoice
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': types === 4 }" (click)="getValue(4)">
        <div>
          <img src="./assets/images/newicons/b4.svg">
        </div>
        <div>
          Road Side<br>
          Assistance
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': types === 5 }" (click)="getValue(5)">
        <div>
          <img src="./assets/images/newicons/b5.svg">
        </div>
        <div>
          Engine & Gear<br>
          Box Protect
        </div>
      </div>
      <div class="addonDIv" [ngClass]="{ 'activeDIv': types === 6 }" (click)="getValue(6)">
        <div>
          <img src="./assets/images/newicons/b6.svg">
        </div>
        <div>
          IMT 23
        </div>
      </div>

    </div>
  </div>
  <div class="row addonDIvs mobile-view">
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': types === 1 }" (click)="getValue(1)">
      <div>
        <img src="./assets/images/newicons/b1.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Zero/Nil<br>
        Depreciation
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': types === 2 }" (click)="getValue(2)">
      <div>
        <img src="./assets/images/newicons/b2.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Consumable<br>
        Cover
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': types === 3 }" (click)="getValue(3)">
      <div>
        <img src="./assets/images/newicons/b3.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Return to<br>
        Invoice
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': types === 4 }" (click)="getValue(4)">
      <div>
        <img src="./assets/images/newicons/b4.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Road Side<br>
        Assistance
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': types === 5 }" (click)="getValue(5)">
      <div>
        <img src="./assets/images/newicons/b5.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Engine & Gear<br>
        Box Protect
      </div>
    </div>
    <div class="col-md-2 col-sm-5 col-xs-12 " [ngClass]="{ 'activeDIv': types === 6 }" (click)="getValue(6)">
      <div>
        <img src="./assets/images/newicons/b6.svg" class="imgresponsiveaddon">
      </div>
      <div>
        Daily<br>
        Allowance
      </div>
    </div>


  </div>
  <div
    style="text-align: center;margin-top: 50px;width: 80%; margin-left: 10%; background-color: var(--grey1); padding:15px 50px 15px 50px; border-radius: 12px;     font-size: large; margin-bottom: 30px;">
    {{text}}
  </div>
</div>

<div class="containernew ">
  <div>
    <div class="headernew">
      Categories of Commercial Vehicle
    </div>
    <div class="row">
      <div class="contents">
        <div class="box">
          <div class="box-placeholder">
            <img src="./assets/images/newicons/cate1.svg" class="videoClass">
          </div>
          <div class="box-content" id="pcv">
            <h3>PCV</h3>
            <p>A Passenger Carrying Vehicles are designed primarily for transporting passengers from one place to
              another. These vehicles are used by businesses to provide transportation services and often include Buses,
              Minivans, Tourist Vehicles, Taxis, Vans etc.</p>
            <p>Insurance for PCV are specifically covered to address risks related to transporting people, including
              liability for injuries to passengers and damages resulting from accidents.</p>
          </div>
        </div>
        <div class="box">
          <div class="box-content" id="gcv">
            <h3>GCV</h3>
            <p>A Goods Carrying Vehicle are designed or used primarily for transporting goods or cargo. This includes a
              variety of vehicle types, such as Trucks, Delivery Vans, Lorries, Pickups, Refrigerated Trucks, Tankers
              etc.</p>
            <p>These vehicles are essential for various industries, including logistics, retail, manufacturing, and
              more, helping businesses move their products efficiently from one location to another.</p>
          </div>
          <div class="box-placeholder">
            <img src="./assets/images/newicons/cate2.svg" class="videoClass">
          </div>
        </div>
        <div class="box">
          <div class="box-placeholder">
            <img src="./assets/images/newicons/cate3.svg" class="videoClass">
          </div>
          <div class="box-content" id="misc">
            <h3>Misc</h3>
            <p>Miscellaneous Vehicles refer to a category of vehicles that are not typically covered under standard
              commercial vehicle policies but are used for specific business purposes. These vehicles often include
              Cranes, Forklifts, Delivery Vehicles, Construction Vehicles, Agriculture Equipment etc.</p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="become">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <div class="col-md-12 ">
        <div class="">
          <h1 style="font-size: 40px; text-align: center;">How to choose best Commercial Vehicle Insurance?</h1>
        </div>
        <div style="margin-top: 35px;">
          <img style="width: 100%;" src="./assets/images/newicons/truckgroup.svg">
        </div>
      </div>
    </div>
  </div>
</div>

<div class="whatins">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-7">
        <h2 class="whatinsh1">What is Covered in
          Commercial Vehicle Insurance?</h2>
        <span>
          <p style="padding-top: 15px;" class="insText2">
            1. Commercial vehicle insurance covers loss or damage from fire, explosion, theft, and natural disasters
            like floods and earthquakes.<br>
            2. It includes protection against damages caused by human activities such as riots and terrorist acts.<br>
            3. Financial protection is provided for third-party liabilities, including injuries or death to individuals
            and damage to their property.<br>
            4. Personal accident cover is available for the owner-driver, offering compensation for injuries or death
            resulting from violent accidents.<br>
            5. Towing assistance is included for disabled vehicles.
          </p>

        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;margin-bottom: 20px;">
          <a class="earnButton">Know More About Insurance</a>
        </div> -->
      </div>
      <div class="col-md-5 whatimage">
        <img src="./assets/images/newicons/covercar.svg" class="imgresponsive">
      </div>
    </div>
  </div>
</div>

<div class="whyins1">
  <div class="container mt-5 mb-5 ">
    <div class="row">
      <div class="col-md-5 whyimage" >
        <img src="./assets/images/newicons/notcovercar.svg" class="imgresponsive">
      </div>
      <div class="col-md-7">
        <h2 class="whyinsh1">
          What is Not Covered in Commercial Vehicle Insurance?</h2>
        <span>
          <p style="padding-top: 20px;" class="insText2">
            Commercial Vehicle Insurance in India does not cover certain situations and damages. These include:<br>
            1. Regular wear and tear of the vehicle, Mechanical or electrical breakdowns<br>
            2. Damage caused while driving under the influence of alcohol or drugs<br>
            3. Damage due to illegal activities or usage<br>
            4. Damage incurred while driving without a valid license<br>
            5. Use of the vehicle outside the geographical area specified in the policy
          </p>
        </span>
        <!-- <div style="width: 100%; text-align: center; margin-top: 30px;">
          <a class="earnButton">Know more about it</a>
        </div> -->

      </div>

    </div>
  </div>
</div>

<div class="faq">
  <div class="container mt-5 mb-5 ">
    <div class="row" style="padding-top: 50px;">
      <h2>FAQs</h2>
    </div>
    <div class="row" style="padding-top: 15px;">
      <div class="col-md-6">

        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(0)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 0, 'fa-minus': openItemIndex === 0 }"
                aria-hidden="true"></i>
              1. What is commercial vehicle insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 0 }"> Commercial Vehicle Insurance provides
              coverage for vehicles used for business purposes, protecting against liabilities, damages, and losses.
            </div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(1)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 1, 'fa-minus': openItemIndex === 1 }"
                aria-hidden="true"></i>
              2. Why do I need commercial vehicle insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 1 }">It is mandatory by law and offers
              financial protection against accidents, damages, and liabilities, ensuring your business operations can
              continue smoothly.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(3)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 3, 'fa-minus': openItemIndex === 3 }"
                aria-hidden="true"></i>
              3. What does a commercial vehicle insurance policy cover?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 3 }"> It covers third-party liabilities,
              damage to your own vehicle, theft, fire, natural disasters, and personal accident coverage for the drive
            </div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(4)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 4, 'fa-minus': openItemIndex === 4 }"
                aria-hidden="true"></i>
              4. What types of vehicles are covered under Passenger Carrying Vehicle (PCV) insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 4 }">PCV insurance covers vehicles used to
              transport passengers for business purposes, such as buses, taxis, and vans..</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(5)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 5, 'fa-minus': openItemIndex === 5 }"
                aria-hidden="true"></i>
              5. What is Goods Carrying Vehicle (GCV) insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 5 }">GCV insurance provides coverage for
              vehicles used to transport goods and merchandise, including trucks, delivery vans, and lorries.</div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="faq">
          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(6)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 6, 'fa-minus': openItemIndex === 6 }"
                aria-hidden="true"></i>
              6. What are Miscellaneous Vehicles in commercial vehicle insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 6 }"> Miscellaneous Vehicles include
              non-standard or specialized vehicles like cranes, forklifts, utility vehicles, and construction equipment
              used for business purposes.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(7)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 7, 'fa-minus': openItemIndex === 7 }"
                aria-hidden="true"></i>
              7. What is a geographical extension in commercial vehicle insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 7 }">Geographical extension is an add-on that
              extends the coverage of your commercial vehicle insurance policy to neighboring countries, such as Nepal,
              Bhutan, Bangladesh, Pakistan, China and Sri Lanka.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(8)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 8, 'fa-minus': openItemIndex === 8 }"
                aria-hidden="true"></i>
              8. What is Gross Vehicle Weight (GVW)?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 8 }"> GVW is the maximum permissible weight
              of a vehicle, including its own weight and the weight of its cargo and passengers. It is a crucial factor
              in determining the insurance premium.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(9)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 9, 'fa-minus': openItemIndex === 9 }"
                aria-hidden="true"></i>
              9. How do I file a claim for commercial vehicle insurance?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 9 }">In case of an incident, notify your
              insurer immediately, provide necessary documents (like FIR, driving license, registration certificate, and
              claim form), and follow the insurer’s process for claim assessment and settlement.</div>
          </div>

          <div class="faq-item">
            <div class="faq-question" (click)="toggleAnswer(10)">
              <i class="fa faq-icon" [ngClass]="{ 'fa-plus': openItemIndex !== 10, 'fa-minus': openItemIndex === 10 }"
                aria-hidden="true"></i>
              10. What is IMT23 cover?
            </div>
            <div class="faq-answer" [ngClass]="{ 'open': openItemIndex === 10 }">IMT 23 coverage is an insurance rider
              for GCV that guarantees the policyholder will receive at least 50% coverage for damage to items such as
              paint, bonnet, fender, bumper, and more.</div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<div class="container-fluid background">
  <div class="container partners_section">
    <div class="card-container ">
      <div class="justify-content">
        <div class="text " style="margin-top: 3%;">
          <!-- <h1 class="heading">Our <span style="color:var(--main2)">Partners</span></h1> -->
          <h1 class="heading">Our Commercial Vehicle Insurance Partners</h1>
          <span class="font-body">We work together to provide you the best services.</span>
        </div>

        <div class="container ">
          <div style="text-align: center;">
            <div class="image-row">
              <img *ngFor="let img of images" [src]="img" alt="">
            </div>
            <div class="image-row">
              <img *ngFor="let img of images1" [src]="img" alt="">
            </div>
            <!-- <div style="width: 100%; text-align: center;margin-bottom: 20px;">
              <a *ngIf="!showAllImages" class="butn" (click)="showAllImages = true">Show More</a>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>