import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from '../../Models/login.component.model';
import { LoginService } from '../../Services/login.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import swal from 'sweetalert2';
import { MenuService } from '../../Services/menu.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { InsuranceService } from 'src/app/Services/insurance.service';
import { NavbarComponent } from 'src/app/navbar/navbar.component';
import { SignupService } from 'src/app/Services/signup.service';
import { VehicleDetails } from 'src/app/Models/vehicle-component.model';
import { PincodeMaster } from 'src/app/Models/signUp.component.model';
import { GeneralService } from 'src/app/Services/general.service';
import * as moment from 'moment';
//import { HeaderMenuComponent } from 'src/app/navbar/header-menu/header-menu.component';


@Component({
  selector: 'app-quotes-login',
  templateUrl: './quotes-login.component.html',
  styleUrls: ['./quotes-login.component.css']
})
export class QuotesLoginComponent {
  loginForm: FormGroup;
  signupForm: FormGroup;
  resetPassForm: FormGroup;
  AlertMessage: string = '';
  passwordShow: boolean = false;
  passwordShow1: boolean = false;
  passwordShow2: boolean = false;
  IsAlert: boolean = false;
  public passwordType: string = PasswordType.pwd;
  public passwordType1: string = PasswordType.pwd;
  public passwordType2: string = PasswordType.pwd;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;
  ServicesDown: boolean = false;
  signSubject: Subject<any> = new Subject<any>();
  list: any = [];
  today=new Date();
  maxDate = moment(this.today).add(-18, 'years').format('YYYY-MM-DD');
restPass:boolean=false;
  @Input() isBike:boolean;
  @Output() action = new EventEmitter();
  // @ViewChild(HeaderMenuComponent) navbarComponent: HeaderMenuComponent;
  constructor(private loginService: LoginService, private signupService: SignupService, private router: Router, private fb: FormBuilder,
     private spinner: NgxSpinnerService, private _menuservice: MenuService,public modalRef: BsModalRef, private modalService: BsModalService,
     private insuranceService:InsuranceService, private generalService:GeneralService) {
    this.loginForm = this.fb.group({
      "PhoneNumber": ['', Validators.required],
      "Password": ['', [Validators.required]],
      "recaptchaReactive":['', [Validators.required]]
    });
    this.signupForm = this.fb.group({
      "referralCode": [''],
      "otp": [''],
      "firstName": [''],
      "lastName": [''],
      "userName": [''],
      "phoneNumber": ['', [Validators.required]],
      "userEmail": [''],
      "streetAddress": ['T'],
      "pincodeId": ['66416'],
      "gender": ['Male'],
      "dob": [],
      "countryCode": [''],
      "passcode": [''],
      "confirmPassword": [''],
      "recaptchaReactive":['']
    });
    this.resetPassForm =  this.fb.group({
      "PhoneNumber": ['', [Validators.required]],
      "password": [''],
      "password1": [''],
      "confirm_password": [''],
      "otpNumber":['']
    });
    this.generalService.removeAllSession();
    this.StatusCheck();
  }

  async StatusCheck() {
    this.spinner.show();
    try {
      const response = await this.loginService.StatusCheck();
      if (response === ``) {
        swal.fire({
          position: 'center',
          icon: 'error',
          title: `Website currently down, apologize for the inconvenience.`,
          showConfirmButton: false,
          timer: 3000,
        });
        this.ServicesDown = true;
      }
     
    }
    catch (error) {
      console.log(error);
    }
    this.spinner.hide();
  }

  async submit() {
    ;
    try {
      this.spinner.show();

      if (this.loginForm.get('PhoneNumber')?.value != null && typeof (this.loginForm.get('PhoneNumber')?.value) == 'object') {
        let phone = this.loginForm.get('PhoneNumber')?.value;
        this.loginForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      }

      const request = { PhoneNumber: this.loginForm.get('PhoneNumber')?.value, Password: window.btoa(this.loginForm.get('Password')?.value),OTP_FOR:"CUST" }
      const res = await this.loginService.AuthenticateUser(request);

      if (res?.isSuccess) {
        let data = res.result;
        this.generalService.setAccessToken(data.accessToken);
        this.generalService.setUniqueuserid(data.uniqueUserId);
        this.generalService.setIsAgent(data.agentId>0?'true':'false');
        this.generalService.setEmployeeFlag(data.employee);
        await this.getDistributorSettings();
        //this.router.navigate(['/dashboard']);
        ///if(this.list[0].vehicleData.vehiclecategory =="2WN")
        //  this.router.navigate(['bike']);
        //  else
        //  this.router.navigate(['car']);
        sessionStorage.setItem('quotes_data', JSON.stringify(this.list[0].quote));
        sessionStorage.setItem('vehicle_data', JSON.stringify(this.list[0].vehicleData));
        sessionStorage.setItem('IsMockup', JSON.stringify(this.list[0].IsMockup));
        ;
        //this.navbarComponent.isUserLogged = true;
        //this.headerMenuComponent.isUserLogged = true;
        this.action.emit(true);
        
        this.modalRef.hide();
      } else {
        swal.fire({
          position: 'center',
          // icon: 'error',
          // title: res?.message,
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          title: 'Authentication Failed',
          text: res?.message ??'Please check and re-enter your valid mobile number or password and try again.',
          showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            },
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.spinner.hide();
    }
  }


  async getDistributorSettings() {
    const response = await this._menuservice.GetDistributorSettings();
    if (response?.isSuccess) {
      sessionStorage.setItem('distributor_settings', response.result);
    }
  }
  ShowPassword() {
    if (!this.passwordShow) {
      this.passwordType = PasswordType.txt;
      this.passwordShow = true;
    }
    else {
      this.passwordType = PasswordType.pwd;
      this.passwordShow = false;
    }
  }
  onCountryChangeNew(e: any) {
    this.loginForm.get('phoneNumber')?.setValue("");
  }

  closeModal() {
    this.modalRef.hide();

  }
  signupBackF() {
    this.isSignin = false;
    this.isforgotpassword = true;
    this.isSignUpDiv = false;
  }
  forgotBackF() {
    this.isSignin = true;
    this.isforgotpassword = false;
  }
  signUp:string = 'quote-signUp';
  isSignin: boolean = true;
  isSignUpDiv: boolean = false;
  isSignup: boolean = true;
  phoneNumber: string = '';
  randOTP:string ="";
  isOtp: boolean = false;
  timerSpanValue: boolean = false;
  isResendEnabled: boolean = true;
  timerValue: number = 60; // Start at 30 seconds
  timer: any; // To store the interval reference
  isNewUser: boolean = false;
  dob: Date | null = null;
  isReff: boolean = true;
  showPassword: boolean = false;
  showPassword1: boolean = false;
  showPassword2: boolean = false;
  vehicleData: VehicleDetails = {};
  quoteRoute: boolean = false;
  pincodeMaster = new PincodeMaster();
  pincodeMasterId: any;
  isforgotpassword: boolean = false;
  isForgot: boolean = true;
  mobileNo: string = '';
  enterOTP = new FormControl("", [Validators.required]);
  isReset: boolean = false;
  @ViewChild('otp') otp: any;
  quoteSignup(){
    this.isSignin = false;
    this.isSignUpDiv = true;
    this.isforgotpassword = false;
    localStorage.setItem('routeName', this.signUp);
  }
  quotesSignInF() {
    this.isSignUpDiv = false;
    this.isSignin = true;
    this.isforgotpassword = false;
  }
  forgotPassword() {
    this.isforgotpassword = true;
    this.isSignUpDiv = false;
    this.isSignin = false;
    this.isReset = false;
    this.isOtp = false;
    this.isForgot = true;
    this.resetPassForm.get('otpNumber')?.clearValidators();
    this.resetPassForm.get('otpNumber')?.updateValueAndValidity();
  }
  async PhoneExists() {
    
    if (this.signupForm.get('phoneNumber')?.value != null && typeof (this.signupForm.get('phoneNumber')?.value) == 'object') {
      let phone = this.signupForm.get('phoneNumber')?.value;
      this.signupForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.phoneNumber = phone.e164Number;
    }
    this.GenerateRandomNo();
    ;
    // OTP
    let request = { OTP_FOR: "CUST",  phoneNumber: this.signupForm.get('phoneNumber')?.value,OTP :'' }
    this.mobileNo = this.signupForm?.get('phoneNumber')?.value?.toString();

    if(request.phoneNumber == undefined || request.phoneNumber == null || 
      (request.phoneNumber != undefined && request.phoneNumber != null && request.phoneNumber.length < 10) )
      {
        // swal.fire({
        //   position: 'center',
        //   icon: 'error',
        //   title: 'Please provide valid mobile number.',
        //   showConfirmButton: false,
        //   timer: 2000,
        // });
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          // icon: 'error',
          title: 'Please provide valid mobile number.',
          //text: 'Please check your password & confirm password and try again. Thank you for your patience.',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Ok',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          },
          // timer: 9000,
        });
      }
      else
      {
        const response = await this.signupService.CheckUserForSignup(request);
        if (response?.code == 1) {
          this.startTimer();
          this.isSignup = false;
          this.isOtp = true;
          this.signupForm.get('otp')?.setValidators([Validators.required]);
          this.signupForm.get('otp')?.updateValueAndValidity();
        }
        else {
          swal.fire({
            position: 'center',
            imageUrl: "../../assets/alerts/warning.png",
            imageHeight: 89,
            // icon: 'error',
            title: response?.message,
            //text: 'Please check your password & confirm password and try again. Thank you for your patience.',
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            },
            // timer: 9000,
          });
        }
      }
  }

  Showpassword1() {
    if (!this.passwordShow1) {
      this.passwordType1 = PasswordType.txt;
      this.passwordShow1 = true;
    }
    else {
      this.passwordType1 = PasswordType.pwd;
      this.passwordShow1 = false;
    }
  }

  Showpassword2() {
    if (!this.passwordShow2) {
      this.passwordType2 = PasswordType.txt;
      this.passwordShow2 = true;
    }
    else {
      this.passwordType2 = PasswordType.pwd;
      this.passwordShow2 = false;
    }
  }
  GenerateRandomNo()
  {
      this.randOTP = "";
      let range = {min: 100000, max: 999999}
      let delta = range.max - range.min
      this.randOTP = Math.round(range.min + Math.random() * delta).toString();
  }
  changemobile() {
    this.isOtp = false;
    this.isForgot = true;
    this.resetPassForm.get('otpNumber')?.clearValidators();
    this.resetPassForm.get('otpNumber')?.updateValueAndValidity();
  }

  changemobileSignUp() {
    this.isOtp = false;
    this.isSignup = true;
    this.signupForm.get('otp')?.clearValidators();
    this.signupForm.get('otp')?.updateValueAndValidity();
  }

  startTimer() {
    this.timerValue = 60;
    this.timerSpanValue = true;
    this.timer = setInterval(() => {
      if (this.timerValue > 0) {
        this.timerValue--;
      } else {
        this.stopTimer();
        
      }
    }, 1000); // Update every 1 second (1000 milliseconds)
  }
  async sendOtp() {
    // this.isForgot = false;
    // this.isOtp = !this.isOtp
    // this.isSignup = !this.isSignup;
    // this.stopTimer();

    this.GenerateRandomNo();
    // this.resetPassForm.get('PhoneNumber')?.value 
    this.mobileNo  ="";
    if (this.resetPassForm.get('PhoneNumber')?.value != null && typeof (this.resetPassForm.get('PhoneNumber')?.value) == 'object') {
      let phone = this.resetPassForm.get('PhoneNumber')?.value;
      this.resetPassForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
    }
    this.loginForm?.get('PhoneNumber')?.setValue(this.resetPassForm?.get('PhoneNumber')?.value);
    this.mobileNo = this.resetPassForm?.get('PhoneNumber')?.value;
    let request = { OTP_FOR: "CUST",  phoneNumber: this.resetPassForm?.get('PhoneNumber')?.value?.toString()   }
      const response = await this.loginService.SendOtp(request);
      if (response?.code == 1) {
        this.startTimer();
        this.isOtp = true;
        this.isForgot = false;
        this.spinner.hide();
        this.resetPassForm.get('otpNumber')?.addValidators([Validators.required, Validators.pattern('[0-9]{6}')]);
        this.resetPassForm.get('otpNumber')?.updateValueAndValidity();
      }
      else{
        this.spinner.hide();
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          title: response?.message??'Phone number not exists',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Ok',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          }
        });
      }
  }

  async resendOTP()
  {

    if (this.resetPassForm.get('PhoneNumber')?.value != null && typeof (this.resetPassForm.get('PhoneNumber')?.value) == 'object') {
      let phone = this.resetPassForm.get('PhoneNumber')?.value;
      this.resetPassForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
    }
    this.loginForm?.get('PhoneNumber')?.setValue(this.resetPassForm?.get('PhoneNumber')?.value);
    this.mobileNo = this.resetPassForm?.get('PhoneNumber')?.value;
    let request = { OTP_FOR: "CUST",  phoneNumber: this.resetPassForm?.get('PhoneNumber')?.value?.toString()   }
      const response = await this.loginService.SendOtp(request);
      if (response?.code == 1) {
        this.startTimer();
        this.isOtp = true;
        this.isForgot = false;
        this.spinner.hide();
        this.resetPassForm.get('otpNumber')?.addValidators([Validators.required, Validators.pattern('[0-9]{6}')]);
        this.resetPassForm.get('otpNumber')?.updateValueAndValidity();
        swal.fire({
          position: 'center',
          // icon: 'success',
          imageUrl: "../../assets/alerts/success.png",
          imageHeight: 89,
          title: 'OTP sent successfully!',
          showConfirmButton: true,
          confirmButtonText: 'ok',
          confirmButtonColor: "#0DB561",
        });     
      }
  }

  async resendOTPSignUp()
  {
    ;
    if (this.signupForm.get('phoneNumber')?.value != null && typeof (this.signupForm.get('phoneNumber')?.value) == 'object') {
      let phone = this.signupForm.get('phoneNumber')?.value;
      this.signupForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.signupForm.get('countryCode')?.setValue(phone.dialCode);
    }
    this.mobileNo = this.signupForm?.get('phoneNumber')?.value?.toString();
      let request = { OTP_FOR: "CUST",  phoneNumber: this.signupForm?.get('phoneNumber')?.value?.toString()   }
      ////const response = await this.loginService.SendOtp(request);
  
      const response = await this.signupService.CheckPhoneExists(request);
      if (response?.code == 1) {
        this.startTimer();
        swal.fire({
          position: 'center',
          // icon: 'success',
          imageUrl: "../../assets/alerts/success.png",
          imageHeight: 89,
          title: 'OTP sent successfully!',
          showConfirmButton: true,
          confirmButtonText: 'ok',
          confirmButtonColor: "#0DB561",
        });        
      }
      this.spinner.hide();
  }


  async validateOtp() {
    ;
    if(this.resetPassForm.valid)
    {
      this.spinner.show();          
    
      if (this.resetPassForm.get('PhoneNumber')?.value != null && typeof (this.resetPassForm.get('PhoneNumber')?.value) == 'object') {
        let phone = this.resetPassForm.get('PhoneNumber')?.value;
        this.resetPassForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      }
      
      let request = { PhoneNumber: this.resetPassForm.get('PhoneNumber')?.value.toString(), OTP: this.resetPassForm.get('otpNumber')?.value.toString() ,GeneratedOTP:''};
      const response = await this.loginService.ValidateOTP(request);
      if (response?.code == 1) {
        this.isForgot=false;
        this.isOtp=false;
        this.isReset=true;
        this.spinner.hide(); 
      }
      else {
        this.spinner.hide(); 
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          // icon: 'error',
          title: response?.message ?? 'Invalid OTP',
          text: 'Please check and re-enter your valid OTP number and try again. Thank you for your patience.',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Try Again',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          },
          // timer: 9000,
        });
      }
    }
  }

  async validateOtp1() {
    ;
    this.spinner.show();
    if (this.signupForm.get('phoneNumber')?.value != null && typeof (this.signupForm.get('phoneNumber')?.value) == 'object') {
      let phone = this.signupForm.get('phoneNumber')?.value;
      this.signupForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.signupForm.get('countryCode')?.setValue(phone.dialCode);
    }

    let request = { PhoneNumber: this.signupForm.get('phoneNumber')?.value, OTP: this.signupForm.get('otp')?.value ,GeneratedOTP:''};
  
    const response = await this.signupService.ValidateOTP(request);
    if (response?.code == 1) {
      this.isOtp = false;
      this.isNewUser = true;
      this.EnableDetailsRequired();
    }
    else {
      this.spinner.hide(); 
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          // icon: 'error',
          title: response?.message ?? 'Invalid OTP',
          text: 'Please check and re-enter your valid OTP number and try again. Thank you for your patience.',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Try Again',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          },
          // timer: 9000,
        });
    }
    this.spinner.hide();

  }
  stopTimer() {
    clearInterval(this.timer);
  }
  EnableDetailsRequired() {
    this.signupForm.get('firstName')?.clearValidators();
    this.signupForm.get('firstName')?.updateValueAndValidity();
    this.signupForm.get('lastName')?.clearValidators();
    this.signupForm.get('lastName')?.updateValueAndValidity();
    // this.signupForm.get('userName')?.setValidators([Validators.required]);
    // this.signupForm.get('userName')?.updateValueAndValidity;
    this.signupForm.get('userEmail')?.setValidators([Validators.required]);
    this.signupForm.get('userEmail')?.updateValueAndValidity();
    this.signupForm.get('dob')?.setValidators([Validators.required]);
    this.signupForm.get('dob')?.updateValueAndValidity();
    this.signupForm.get('gender')?.setValidators([Validators.required]);
    this.signupForm.get('gender')?.updateValueAndValidity();
    // this.signupForm.get('streetAddress')?.setValidators([Validators.required]);
    // this.signupForm.get('streetAddress')?.updateValueAndValidity;
    // this.signupForm.get('pincode')?.setValidators([Validators.required]);
    // this.signupForm.get('pincode')?.updateValueAndValidity;
    this.signupForm.get('passcode')?.setValidators([Validators.required]);
    this.signupForm.get('passcode')?.updateValueAndValidity();
    this.signupForm.get('confirmPassword')?.setValidators([Validators.required]);
    this.signupForm.get('confirmPassword')?.updateValueAndValidity();
    this.loginForm.get('recaptchaReactive')?.setValidators([Validators.required]);
    this.loginForm.get('recaptchaReactive')?.updateValueAndValidity();
  }
  DisableDetailsRequired() {
    this.signupForm.get('firstName')?.clearValidators();
    this.signupForm.get('firstName')?.updateValueAndValidity();
    this.signupForm.get('lastName')?.clearValidators();
    this.signupForm.get('lastName')?.updateValueAndValidity();
    // this.signupForm.get('userName')?.clearValidators();
    // this.signupForm.get('userName')?.updateValueAndValidity;
    this.signupForm.get('userEmail')?.clearValidators();
    this.signupForm.get('userEmail')?.updateValueAndValidity();
    this.signupForm.get('dob')?.clearValidators();
    this.signupForm.get('dob')?.updateValueAndValidity();
    this.signupForm.get('gender')?.clearValidators();
    this.signupForm.get('gender')?.updateValueAndValidity();
    this.signupForm.get('streetAddress')?.clearValidators();
    this.signupForm.get('streetAddress')?.updateValueAndValidity();
    this.signupForm.get('pincode')?.clearValidators();
    this.signupForm.get('pincode')?.updateValueAndValidity();
    this.signupForm.get('passcode')?.clearValidators();
    this.signupForm.get('passcode')?.updateValueAndValidity();
    this.signupForm.get('confirmPassword')?.clearValidators();
    this.signupForm.get('confirmPassword')?.updateValueAndValidity();
    this.signupForm.get('recaptchaReactive')?.clearValidators();
    this.signupForm.get('recaptchaReactive')?.updateValueAndValidity();
  }
  confirmdetails() {
    // this.dataSubject.next();
    
  }
  isView: boolean = false;
  isNoView: boolean = true;
  showHidePassword()
  {
    this.showPassword = !this.showPassword;
    if(this.showPassword==true)
    {
      this.isView = true;
      this.isNoView = false;
    }
    else{
      this.isView = false;
      this.isNoView = true;
    }
  }
  async signupSubmit() {
    this.spinner.show();
    if (this.signupForm.get('passcode')?.value != this.signupForm.get('confirmPassword')?.value) {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Password and confirm password should be same',
        showConfirmButton: false,
        timer: 3000,
      });
      return;
    }

    ;

    if (this.signupForm.get('phoneNumber')?.value != null && typeof (this.signupForm.get('phoneNumber')?.value) == 'object') {
      let phone = this.signupForm.get('phoneNumber')?.value;
      this.signupForm.get('phoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
      this.signupForm.get('countryCode')?.setValue(phone.dialCode);
    }

    // this.signupForm.get('otp')?.setValue(this.signupForm.value.otp.toString());

    // this.signupForm.get('passcode')?.setValue(window.btoa(this.signupForm.value.passcode));
    // this.signupForm.get('confirmPassword')?.setValue(window.btoa(this.signupForm.value.confirmPassword));
    // this.signupForm.get('userName')?.setValue(this.signupForm.value.firstName + ' ' + this.signupForm.value.lastName);
    // if (this.pincodeMaster?.id != null && this.pincodeMaster?.id != undefined && this.pincodeMaster?.id != 0) {
    //   this.signupForm.get('pincodeId')?.setValue(this.pincodeMaster?.id);
    // }

    let obj ={ 
      referralCode:"",
      firstName:this.signupForm.value.firstName,
      lastName:this.signupForm.value.lastName,
      phoneNumber:this.signupForm.get('phoneNumber')?.value,
      userName:this.signupForm.value.firstName + ' ' + this.signupForm.value.lastName,
      userEmail:this.signupForm.value.userEmail,
      streetAddress:this.signupForm.value.streetAddress,
      pincodeId : this.pincodeMaster?.id != null && this.pincodeMaster?.id != undefined && this.pincodeMaster?.id != 0 ? this.pincodeMaster?.id : this.signupForm.value.pincodeId,
      gender:this.signupForm.value.gender,
      dob:this.signupForm.value.dob,
      countryCode : this.signupForm.get('countryCode')?.value,
      otp: this.signupForm.value.otp.toString(),
      passcode:window.btoa(this.signupForm.value.passcode),
      confirmPassword:window.btoa(this.signupForm.value.confirmPassword)      
    };

    const response = await this.signupService.SignUpUser(obj);
    if (response?.isSuccess && response?.result && response?.result?.accessToken != null && response?.result?.accessToken != undefined && response?.result?.accessToken != "" ) {            
      this.generalService.setAccessToken(response.result.accessToken);
      this.generalService.setUniqueuserid(response.result.uniqueUserId);
      if(this.quoteRoute == true){
        const initialState: any = {
          list: [
            {
              "quote": localStorage.getItem('quote'),
              "vehicleData": this.vehicleData,
              "IsMockup": false
            }
          ]
        };
        this.modalRef = this.modalService.show(QuotesLoginComponent, { initialState });
      }else{
        this.modalRef.hide();
        this.router.navigate(['/']);
      }
      //localStorage.clear();
    }
    else
    {
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: response?.result?.message??"Something went wrong!",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
        customClass: {
          confirmButton: "error_btn",
        }
      });
    }
    this.spinner.hide();
  }
  async resetPassword() {
    let request = { OTP:  this.otp.nativeElement.value ,GeneratedOTP:'',PhoneNumber:this.mobileNo}
    
    const response = await this.signupService.ValidateOTP(request);
    if (response?.code == 1) {
      this.isReset = !this.isReset
      this.isOtp = !this.isOtp
    }
    else{
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        // icon: 'error',
        title: response?.message ?? 'Invalid OTP',
        text: 'Please check and re-enter your valid OTP number and try again. Thank you for your patience.',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Try Again',
        cancelButtonColor: "#DF3A4D",
        customClass: {
          confirmButton: "error_btn",
        },
        // timer: 9000,
      });
    }

  }
  numberOnly(event: { which: any; keyCode: any; }): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
         return false;
      }
      return true;
  
  }
  async passwordReset() {
    if(this.resetPassForm.value.password1 && this.resetPassForm.value.confirm_password){
     if(this.resetPassForm.value.password1 !==this.resetPassForm.value.confirm_password ){
       swal.fire({
         position: 'center',
         icon: 'error',
         title: 'New password and confrm password do not match',
         showConfirmButton: false,
         timer: 3000,
       });
      // return false;
     }
    else{
     if (this.resetPassForm.get('PhoneNumber')?.value != null && typeof (this.resetPassForm.get('PhoneNumber')?.value) == 'object') {
       let phone = this.resetPassForm.get('PhoneNumber')?.value;
       this.resetPassForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
     }
      this.resetPassForm.get('password')?.setValue(window.btoa(this.resetPassForm.get('password1')?.value)); 
      ;
     const response = await this.loginService.ForgotPasword(this.resetPassForm.value);
     if (response?.isSuccess && response?.result && response?.result.code == 1) {
      swal.fire({
        position: 'center',
        // icon: 'success',
        imageUrl: "../../assets/alerts/success.png",
        imageHeight: 89,
        title: 'Password Reset Successful!!',
        text: 'Your password has been reset successfully. You can now log in with your new password',
        showConfirmButton: true,
        confirmButtonText: 'Login',
        confirmButtonColor: "#0DB561",
        // timer: 3000,
      });
      //  this.router.navigate(['/login']);
      this.isSignin = true;
      this.isforgotpassword = false;
     }
     else
        {
          swal.fire({
            position: 'center',
            imageUrl: "../../assets/alerts/warning.png",
            imageHeight: 89,
            title: response?.result?.message??"Something went wrong!",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            }
          });
        }
    }
   }
     }

      get ResetPassword(){
      return this.resetPassForm.controls;
     }

     get signupFormControl(){
      return this.signupForm.controls;
     }

//      checkCaptchaLogin(captchaResponse : string) {
//       this.captchaResolvedLogin = (captchaResponse && captchaResponse.length > 0) ? true : false
//   }

//   checkCaptchaSignUp(captchaResponse : string) {
//     this.captchaResolvedSignUp = (captchaResponse && captchaResponse.length > 0) ? true : false
// }
}
