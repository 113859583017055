import { Component, ViewChild } from '@angular/core';
import { SignupService } from '../../Services/signup.service';
import { LoginService } from '../../Services/login.service'
import { FormBuilder, FormGroup, FormGroupName, Validators } from '@angular/forms';
import swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { CountryISO, SearchCountryField } from 'ngx-intl-tel-input';
import { PasswordType } from 'src/app/Models/login.component.model';
import { GeneralService } from 'src/app/Services/general.service';
import * as moment from 'moment';
@Component({
  selector: 'app-businesshomepage',
  templateUrl: './businesshomepage.component.html',
  styleUrls: ['./businesshomepage.component.css'],
})
export class BusinesshomepageComponent {
  showDiv1 = false;
  showDiv2 = false;
  showDiv3 = false;
  showDiv4 = false;
  showDiv5 = false;
  showDiv6 = false;
  LoginPopUp: boolean = false;
  popupNo: string = 'login';
  component: string = '1';
  randOTP: string = "";
  mobile: string = '';
  active: string = 'agent';
  success: boolean = false;
  signupForm: FormGroup;
  @ViewChild('otp') otp: any;
  @ViewChild('confirmPassword') confirmPassword: any;
  @ViewChild('password') password: any;
  confirmPassword1: string;
  password1: string;
  // @ViewChild('forgotMobile') forgotMobile: any;
  forgotMobile: string = "";
  openItemIndex = -1;
  // login and logout
  isloggedIn: boolean = true;
  isloggedOut: boolean = false;
  loginForm: FormGroup;
  hospitalSave: FormGroup;
  medicalSave: FormGroup;
  GarageSave: FormGroup;
  DoctorSave: FormGroup;
  InfluencerSave: FormGroup;
  signUp:FormGroup;
  CountryISO = CountryISO;
  separateDialCode = true;
  SearchCountryField = SearchCountryField;
  MobileNumber: number;
  isRememberMe: boolean = false;
  M_Number:any;
  userPass:any;
  submittedLogin:boolean=false;
  signInOtpClicked:boolean = false;
  submittedSignUp:boolean=false;
  sign:boolean=false;
  passBarWidth = "1"; 
  passBarBGColor = "#D73F40";
  passBarText="Too short"
  today = new Date();
  currentYear : number = new Date().getFullYear();
  maxDOB = moment(this.today).add(-18, 'years').format('YYYY-MM-DD');
  activeimg(type: string) {
    this.active = type;
    // this.component=type;
  }
  successPopup() {
    this.success = true;
    this.LoginPopUp = false;
  }
  opencloseAnswer(index: number): void {
    if (this.openItemIndex === index) {
      this.openItemIndex = -1; // Clicking the open item again should close it
    } else {
      this.openItemIndex = index; // Open the clicked item
    }
  }
  checked:any;
  constructor(private signupService: SignupService, private spinner: NgxSpinnerService,
    private loginService: LoginService, private fb: FormBuilder, private router: Router, private generalService: GeneralService) {

      this.M_Number=localStorage.getItem("A_MobileNum");
      this.userPass=localStorage.getItem("A_userPass");
    if (this.isAgent() == true) {
      router.navigate(['/agent-profile']);
    }
    else {
      this.generalService.removeAllSession();
    }
    this.signUp=this.fb.group({
      "signup": ['', [Validators.required]]
    });
    this.hospitalSave = this.fb.group({
      "HospitalName": ['', [Validators.required]],
      "HContact": ['', [Validators.required]],
      "HBusiness_Contact": [''],
      "HEmail_ID": ['', [Validators.required]],
      "HBusinessEmail": [''],
      "HPassword": ['', [Validators.required]],
      "HConfirmPass": ['', [Validators.required]],
      "recaptchaReactive":['', [Validators.required]]
    });


    this.medicalSave = this.fb.group({
      "MedicalName": ['', [Validators.required]],
      "MContact": ['', [Validators.required]],
      "MBusiness_Contact": [''],
      "MEmail": ['', [Validators.required]],
      "MBusiness_Email": [''],
      "MPassword": ['', [Validators.required]],
      "MConfirmPass": ['', [Validators.required]],
      "recaptchaReactive":['', [Validators.required]]
    });



    this.GarageSave = this.fb.group({
      "GarageName": ['', [Validators.required]],
      "GContact": ['', [Validators.required]],
      "GBusiness_Contact": [''],
      "GEmail": ['', [Validators.required]],
      "GBusinessEmail": [''],
      "GPassword": ['', [Validators.required]],
      "GConfirmPass": ['', [Validators.required]],
      "recaptchaReactive":['', [Validators.required]]
    });


    this.DoctorSave = this.fb.group({
      "Doctor_Name": ['', [Validators.required]],
      "DContact": ['', [Validators.required]],
      "DBusinessContact": [''],
      "DEmail": ['', [Validators.required]],
      "DBusinessEmail": [''],
      "DPassword": ['', [Validators.required]],
      "DConfirmPass": ['', [Validators.required]],
      "recaptchaReactive":['', [Validators.required]]
    });
    this.InfluencerSave = this.fb.group({
      "InfluencerName": ['', [Validators.required]],
      "InfluencerContact": ['', [Validators.required]],
      "InfluencerEmail": ['', [Validators.required]],
      "InfluencerPassword": ['', [Validators.required]],
      "InfluencerConfirmPass": ['', [Validators.required]],
      "recaptchaReactive":['', [Validators.required]]
    });

    this.signupForm = this.fb.group({
      "referralCode": [''],
      "otp": [''],
      "firstName": ['', [Validators.required]],
      "lastName": ['', [Validators.required]],
      "userName": [''],
      "phoneNumber": [''],
      "userEmail": ['', [Validators.required,Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      "streetAddress": ['T'],
      "pincodeId": ['66416'],
      "gender": ['Male', [Validators.required]],
      "dob": ['', [Validators.required]],
      "countryCode": [''],
      "passcode": ['', [Validators.required]],//,Validators.pattern("^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$")]
      "ConfirmPassword": ['', [Validators.required]],
      "language": ['', [Validators.required]],
      "recaptchaReactive":['', [Validators.required]],
      "isCustomerToAgent":[false]
    });

    this.loginForm = this.fb.group({
      "PhoneNumber": ['', Validators.required],
      "Password": ['', [Validators.required]],
      "recaptchaReactive":['', [Validators.required]]
    });

  }

  get loginFormControl() {
    return this.loginForm.controls;
  }

  get SignUpControl()
  {
    return this.signUp.controls;
  }
  get signupFormControl() {
    return this.signupForm.controls;
  }

  isHovered1 = false;
  isHovered2 = false;
  isHovered3 = false;
  isHovered4 = false;
  isHovered5 = false;

  pospimg1: boolean = true;
  pospimg2: boolean = false;
  pospimg3: boolean = false;
  pospimg4: boolean = false;
  pospimg5: boolean = false;

  handleMouseOver1() {
    this.isHovered1 = true;
    this.pospimg1 = true;
    this.pospimg2 = false;
    this.pospimg3 = false;
    this.pospimg4 = false;
    this.pospimg5 = false;
  }

  handleMouseLeave1() {
    this.isHovered1 = false;
    this.pospimg1 = true;
    this.pospimg2 = false;
    this.pospimg3 = false;
    this.pospimg4 = false;
    this.pospimg5 = false;
  }

  handleMouseOver2() {
    this.isHovered2 = true;
    this.pospimg1 = false;
    this.pospimg2 = true;
    this.pospimg3 = false;
    this.pospimg4 = false;
    this.pospimg5 = false;
  }

  handleMouseLeave2() {
    this.isHovered2 = false;
    this.pospimg1 = false;
    this.pospimg2 = true;
    this.pospimg3 = false;
    this.pospimg4 = false;
    this.pospimg5 = false;
  }

  handleMouseOver3() {
    this.isHovered3 = true;
    this.pospimg1 = false;
    this.pospimg2 = false;
    this.pospimg3 = true;
    this.pospimg4 = false;
    this.pospimg5 = false;
  }

  handleMouseLeave3() {
    this.isHovered3 = false;
    this.pospimg1 = false;
    this.pospimg2 = false;
    this.pospimg3 = true;
    this.pospimg4 = false;
    this.pospimg5 = false;
  }

  handleMouseOver4() {
    this.isHovered4 = true;
    this.pospimg1 = false;
    this.pospimg2 = false;
    this.pospimg3 = false;
    this.pospimg4 = true;
    this.pospimg5 = false;
  }

  handleMouseLeave4() {
    this.isHovered4 = false;
    this.pospimg1 = false;
    this.pospimg2 = false;
    this.pospimg3 = false;
    this.pospimg4 = true;
    this.pospimg5 = false;
  }

  handleMouseOver5() {
    this.isHovered5 = true;
    this.pospimg1 = false;
    this.pospimg2 = false;
    this.pospimg3 = false;
    this.pospimg4 = false;
    this.pospimg5 = true;
  }

  handleMouseLeave5() {
    this.isHovered5 = false;
    this.pospimg1 = false;
    this.pospimg2 = false;
    this.pospimg3 = false;
    this.pospimg4 = false;
    this.pospimg5 = true;
  }

  async submit() {
    this.submittedLogin = true;
    if(this.loginForm.valid){
      try {
        this.spinner.show();
        this.isloggedIn = false;
        this.isloggedOut = true;
        if (this.loginForm.get('PhoneNumber')?.value != null && typeof (this.loginForm.get('PhoneNumber')?.value) == 'object') {
          let phone = this.loginForm.get('PhoneNumber')?.value;
          this.loginForm.get('PhoneNumber')?.setValue(phone.e164Number.replace(phone.dialCode, ''));
        }
  
        const request = { PhoneNumber: this.loginForm.get('PhoneNumber')?.value, Password: window.btoa(this.loginForm.get('Password')?.value) }
        const res = await this.loginService.AuthenticateUser(request);
        if (res?.isSuccess) {
          let data = res.result;

          if(data.isTempPassCode == true){
            swal.fire({
              imageUrl: "../../assets/alerts/success.png",
              imageHeight: 89,
              title:'',
              text: '',
              html: data?.message,
              showDenyButton: false,
              confirmButtonText: "Ok",
              confirmButtonColor:"#0DB561",
              showCancelButton: false,     
            }).then((result) => {
              if (result.isConfirmed) {
                this.component = "1";
                this.popupNo = "resetPassword"
                this.forgotMobile = request.PhoneNumber?.toString();
              } else if (result.isDenied) {
     
              }
            });
          }
          else{
            this.generalService.setIsAgent(data.agentId > 0 ? 'true' : 'false');
            this.generalService.setUniqueuserid(data.uniqueUserId);
            this.generalService.setAccessToken(data.accessToken);
            this.generalService.setEmployeeFlag(data.employee);
            this.router.navigate(['/agent-profile']); 
          }
        } else {
          // swal.fire({
          //   position: 'center',
          //   icon: 'error',
          //   title: res?.message,
          //   showConfirmButton: false,
          //   timer: 3000,
          // });
          if(res?.value?.isCustomerToAgent == true){
            swal.fire({
              imageUrl: "../../assets/alerts/question.png",
              imageHeight: 89,
              title:'',
              text: '',
              html: res?.message,
              showDenyButton: false,
              confirmButtonText: "Yes, I want",
              confirmButtonColor:"#0DB561",
              showCancelButton: true,  
      
              cancelButtonText:"Don't want",     
              denyButtonText: "Don't want",
              denyButtonColor:"#0DB561",
      
            }).then((result) => {
              if (result.isConfirmed) {
                this.component='agent';
                this.signupForm.patchValue(res?.value);
              } else if (result.isDenied) {
      
              }
            });
          }
          else
          {
            swal.fire({
              position: 'center',
              // icon: 'error',
              // title: res?.message,
              imageUrl: "../../assets/alerts/warning.png",
              imageHeight: 89,
              //title: 'Authentication Failed',
              title: res?.message ?? 'Please check and re-enter your valid mobile number or password and try again.',
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonText: 'Ok',
              cancelButtonColor: "#DF3A4D",
              customClass: {
                confirmButton: "error_btn",
              },
            });
          } 
        }
      } catch (error) {
        console.error(error);
      } finally {
        this.spinner.hide();
      }
    }    
  }

  onCountryChangeNew(e: any) {
    this.loginForm.get('phoneNumber')?.setValue("");
  }

  Login() {
    this.popupNo = 'login';
    this.component='1';
    this.LoginPopUp = true;
  }
  closeModal() {
    this.LoginPopUp = false;
    //location.reload();
  }
  Popup(num: string, param?: any): void {
    // this.mobile=param;
    if (num == 'OTP') {
      this.PhoneExists(param);
      this.startTimer();
      // this.sendOtp(param);
    }
    else if (num == 'select')
      this.validateOtp(param, num);
    else if (num == 'ForgotPasswordOTP') {
      this.forgotMobile = param;
      this.sendOtp(param);
      this.startTimer();
    }
    else if (num == 'resetPassword') {
      this.mobile= param.toString();
      this.validateOtp(this.otp.nativeElement.value, num);
    }
    else if (num == 'succes') {
      this.passwordReset();
    }
    else
      this.popupNo = num;

    if (this.popupNo == 'signup'){
      this.LoginPopUp = true;
      this.component = "1";
    }
  }
  next(Component: string): void {
    this.component = Component;
  }

  // SignUp Otp Related functionality
  signUP(num: string): void {
    if (num == 'signup')
      this.LoginPopUp = true;
  }



  //Generate RandomNo
  GenerateRandomNo() {
    this.randOTP = "";
    let range = { min: 100000, max: 999999 }
    let delta = range.max - range.min
    this.randOTP = Math.round(range.min + Math.random() * delta).toString();
  }

  async PhoneExists(mobile: any) {

    if (mobile == "")
      return;
    this.GenerateRandomNo();
    this.mobile = mobile;
    // OTP
    let request = { phoneNumber: mobile, OTP: this.randOTP }

    const response = await this.signupService.CheckUserForSignup(request);
    if (response?.code == 1) {
      this.popupNo = 'OTP';
    }
    else {
      swal.fire({
        imageUrl: "../../assets/alerts/question.png",
        imageHeight: 89,
        title:'',
        text: '',
        html: response?.message,
        showDenyButton: false,
        confirmButtonText: "Yes, I want",
        confirmButtonColor:"#0DB561",
        showCancelButton: true,  

        cancelButtonText:"Don't want",     
        denyButtonText: "Don't want",
        denyButtonColor:"#0DB561",

      }).then((result) => {
        if (result.isConfirmed) {
          //this.component='agent';
          this.component = "1";
          this.popupNo = "login";
          //this.signupForm.patchValue(response?.data);
        } else if (result.isDenied) {

        }
      });
    }
  }

  async sendOtp(mobile: any) {
    this.GenerateRandomNo();
    let request = { phoneNumber: mobile, OTP: this.randOTP }
    console.log(request);
    const response = await this.signupService.SendOtp(request);
    if (response?.code == 1) {
      this.popupNo = 'ForgotPasswordOTP';
    }
    else {
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: response?.message??'Phone number not exists',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
        customClass: {
          confirmButton: "error_btn",
        }
      });
    }




  }

  async validateOtp(otp: any, name: any) {
    this.spinner.show();
    let request = { PhoneNumber: this.mobile, OTP: otp, GeneratedOTP: this.randOTP };

    const response = await this.signupService.ValidateOTP(request);
    if (response?.code == 1) {
      if (name == 'resetPassword') {
        this.popupNo = 'resetPassword';
      }
      else
        this.popupNo = 'select';
    }
    else {
      swal.fire({
        position: 'center',
        icon: 'error',
        title: response?.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
    this.spinner.hide();

  }


  async signUpUser() {
    this.submittedSignUp = true;
    this.spinner.show();
    if (this.signupForm.get('passcode')?.value != this.signupForm.get('ConfirmPassword')?.value) {
      this.spinner.hide();
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: 'Password and confirm password must be same',
        text: 'Please check your password & confirm password and try again. Thank you for your patience.',
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
        customClass: {
          confirmButton: "error_btn",
        }
      });
      return;
    }

    this.signupForm.get('otp')?.setValue(this.randOTP);
    if(this.mobile != ""){
      this.signupForm.get('phoneNumber')?.setValue(this.mobile);
    }
    
    this.signupForm.get('countryCode')?.setValue('+91');
    //this.signupForm.get('passcode')?.setValue(window.btoa(this.signupForm.value.passcode));
    //this.signupForm.get('userName')?.setValue(this.signupForm.value.firstName);
    this.signupForm.get('language')?.setValue(this.signupForm.value.language);

    let obj = {
      "referralCode": this.signupForm.value.referralCode,
      "otp": this.randOTP,
      "firstName": this.signupForm.value.firstName,
      "lastName": this.signupForm.value.lastName,
      "userName": this.signupForm.value.firstName + this.signupForm.value.lastName,
      "phoneNumber": this.signupForm.value.phoneNumber,
      "userEmail": this.signupForm.value.userEmail,
      "streetAddress": 'T',
      "pincodeId": '66416',
      "gender": this.signupForm.value.gender,
      "dob": this.signupForm.value.dob,
      "countryCode": "+91",
      "passcode": window.btoa(this.signupForm.value.passcode),
      "ConfirmPassword": window.btoa(this.signupForm.value.ConfirmPassword),
      "language": this.signupForm.value.language,
      "isCustomerToAgent":this.signupForm.value.isCustomerToAgent
    }
    const response = await this.signupService.AgentSignUpUser(obj);
    if (response?.isSuccess == true && response?.result != undefined && response?.result != null && response?.result?.uniqueUserId != null && response?.result?.uniqueUserId != undefined && response?.result?.uniqueUserId != '') {
      this.generalService.setUniqueuserid(response.result.uniqueUserId);
      this.generalService.setAccessToken(response.result.accessToken);
      this.successPopup();
    }
    else
    {
      swal.fire({
        position: 'center',
        imageUrl: "../../assets/alerts/warning.png",
        imageHeight: 89,
        title: response?.result?.message?? response?.message ??"Something went wrong.",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonText: 'Ok',
        cancelButtonColor: "#DF3A4D",
        customClass: {
          confirmButton: "error_btn",
        }
      });
    }
    this.spinner.hide();    
  }

  async passwordReset() {
    if (this.password.nativeElement.value && this.confirmPassword.nativeElement.value) {
      if (this.password.nativeElement.value !== this.confirmPassword.nativeElement.value) {
        swal.fire({
          position: 'center',
          imageUrl: "../../assets/alerts/warning.png",
          imageHeight: 89,
          title: 'Password and confirm password must be same',
          text: 'Please check your password & confirm password and try again. Thank you for your patience.',
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonText: 'Ok',
          cancelButtonColor: "#DF3A4D",
          customClass: {
            confirmButton: "error_btn",
          }
        });
      }
      else {
        let passcode = window.btoa(this.password.nativeElement.value)
        let request = { PhoneNumber: this.forgotMobile, Password: passcode, OTP: this.randOTP }
        const response = await this.loginService.ForgotPasword(request);
        if (response?.isSuccess && response?.result && response?.result.code == 1) {
          swal.fire({
            position: 'center',
            imageUrl: "../../assets/alerts/success.png",
            imageHeight: 89,
            title: 'Password Reset Successful!!',
            text: 'Your password has been reset successfully. You can now log in with your new password',
            showConfirmButton: true,
            confirmButtonText: 'Login',
            confirmButtonColor: "#0DB561"
          });
          this.Login();
          //  this.router.navigate(['/login']);
        }
        else
        {
          swal.fire({
            position: 'center',
            imageUrl: "../../assets/alerts/warning.png",
            imageHeight: 89,
            title: response?.result?.message??"Something went wrong!",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonText: 'Ok',
            cancelButtonColor: "#DF3A4D",
            customClass: {
              confirmButton: "error_btn",
            }
          });
        }
      }
    }
  }
  reload() {
    this.success=false;
    this.LoginPopUp = true;
    this.popupNo = 'login';
    this.component = '1';
  }

  passwordShow1: boolean = false;
  passwordShow2: boolean = false;
  passwordShow: boolean = false;
  public passwordType1: string = PasswordType.pwd;
  public passwordType2: string = PasswordType.pwd;
  public passwordType: string = PasswordType.pwd;

  ShowPassword() {
    if (!this.passwordShow) {
      this.passwordType = PasswordType.txt;
      this.passwordShow = true;
    }
    else {
      this.passwordType = PasswordType.pwd;
      this.passwordShow = false;
    }
  }


  ShowPassword1() {
    if (!this.passwordShow1) {
      this.passwordType1 = PasswordType.txt;
      this.passwordShow1 = true;
    }
    else {
      this.passwordType1 = PasswordType.pwd;
      this.passwordShow1 = false;
    }
  }

  ShowPassword2() {
    if (!this.passwordShow2) {
      this.passwordType2 = PasswordType.txt;
      this.passwordShow2 = true;
    }
    else {
      this.passwordType2 = PasswordType.pwd;
      this.passwordShow2 = false;
    }
  }
  timerValue: number = 60; // Start at 30 seconds
  timer: any; // To store the interval reference
  startTimer() {
    this.timerValue = 60;
    //this.timerSpanValue = true;
    this.timer = setInterval(() => {
      if (this.timerValue > 0) {
        this.timerValue--;
      } else {
        this.stopTimer();

      }
    }, 1000); // Update every 1 second (1000 milliseconds)
  }

  stopTimer() {
    clearInterval(this.timer);
  }
  isAgent(): boolean {
    return this.generalService.getIsAgent() == 'true'
  }
  onInput(event: any): void {
    const initialValue = event.target.value;
    // Strictly replace anything not a digit.
    event.target.value = initialValue.replace(/[^0-9]/g, '');
    if (initialValue !== event.target.value) {
      event.stopPropagation();
    }
  }

  validateNumberInput(event: KeyboardEvent): void {
    // Allow: backspace, delete, tab, escape, enter, and numbers from the numpad and numbers row
    if ([46, 8, 9, 27, 13].indexOf(event.keyCode) !== -1 ||
      // Allow: Ctrl/cmd+A
      (event.keyCode === 65 && (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: Ctrl/cmd+C
      (event.keyCode === 67 && (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: Ctrl/cmd+X
      (event.keyCode === 88 && (event.ctrlKey === true || event.metaKey === true)) ||
      // Allow: home, end, left, right
      (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    // Ensure that it is a number and stop the keypress if not
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
    }
  }
 
  Autologin() {
    if (this.isRememberMe) {
      this.isRememberMe = false
    
    }
    else if (!this.isRememberMe) {

      this.isRememberMe = true
      localStorage.setItem("A_MobileNum",this.M_Number.number);
      localStorage.setItem("A_userPass",this.userPass)
    
    }
    }
    alphabetOnly(event:any): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122) || charCode == 32) {
        return true;
      }
      return false;
    }
    alphaNumericOnly(event:any): boolean {
      const charCode = (event.which) ? event.which : event.keyCode;
      if ((charCode >= 48 && charCode <= 57) || ((charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))) {
        return true;
      }
      return false;
    }
  
  

validateEmail(event: KeyboardEvent): void {
  const input = (event.target as HTMLInputElement).value;         

if(input.length === 0 && (/[0-9]/.test(event.key) || !/^[a-zA-Z]/.test(event.key) ) )
 {

    event.preventDefault();
  }

}

passKeyPress(event:any){
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode == 32) {
    return false;
  }
  return true;
}
passKeyUp(event:any){
    let point = 0; 
    let value = event.target.value; 
    let widthPower =  
        ["1", "25", "50", "75", "100"]; 
    let colorPower =  
        ["#D73F40", "#DC6551", "#F2B84F", "#BDE952", "#3ba62f"];
    let textPower =  
        ["Too short", "Weak", "Fair", "Good", "Strong"]; 
  
    if (value.length >= 8) { 
        let arrayTest =  
            [/[0-9]/, /[a-z]/, /[A-Z]/, /[^0-9a-zA-Z]/]; 
        arrayTest.forEach((item) => { 
            if (item.test(value)) { 
                point += 1; 
            } 
        }); 
    } 
    this.passBarWidth = widthPower[point]; 
    this.passBarBGColor = colorPower[point];
    this.passBarText = textPower[point];
}
}
