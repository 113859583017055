<div class="user-dashboard">
    <div class="row user-route">
        <form id="PostForm" name="PostForm" autocomplete="off" [action]="royalDetails.paymentURL" method="post">
            <input type="hidden" name="reqType" id="reqType" value="JSON">
            <input type="hidden" name="process"  value="paymentOption">
            <input type="hidden" name="apikey" id="apikey" value="310ZQmv/bYJMYrWQ1iYa7s43084=">
            <input type="hidden" name="agentId" id="agentId" value="BA504099">
            <input type="hidden" name="premium" [value]="royalDetails.premium">
            <input type="hidden" name="quoteId" id="quoteId" [value]="royalDetails.quotE_ID">
            <input type="hidden" name="version_no" id="version_no" value="1">
            <input type="hidden" name="strFirstName" id="strFirstName" [value]="royalDetails.name">
            <input type="hidden" name="strEmail" id="strEmail" [value]="royalDetails.email">
            <input type="hidden" name="strMobileNo" id="strMobileNo" [value]="royalDetails.phoneNumber">
            <input type="hidden" name="isQuickRenew" id="isQuickRenew" value="No">
            <input type="hidden" name="returnUrl" id="returnUrl" [value]="royalDetails.returnUrl">
            <input type="hidden" value="bike" name="vehicleSubLine" id="privatePassengerCar">
            <input type="hidden" value="" name="elc_value" id="elc_value">
            <input type="hidden" name="crossSellProduct" id="crossSellProduct" value="">
            <input type="hidden" name="crossSellQuoteid" id="crossSellQuoteid" value=""> 
            <input type="hidden" name="newSystem" id="newSystem" value="Yes">
            <input type="hidden" name="callingApp" id="callingApp" value="LIFELINE">
            <input type="hidden" value="" name="nonelc_value" id="nonelc_value">
            <input type="hidden" value="Billdesk" name="paymentType"/>
            <input type="hidden" value="BillDesk" name="paymentgateway"/>
            <!-- <input type="submit" class="btn-view-summary" value="Submit" title="Submit"> -->
            </form>
    </div>
</div>


